import {
  siteMetadata
} from "../../../gatsby-config"

// base react and gatsby imports
import React from "react"

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

import withMessage from "../displayMessage";

// nodejs library that concatenates classes
import classnames from "classnames";

function jsonRequest(endpoint, method, payload){
  const url = `${siteMetadata.drupalUrl}/${endpoint}`;
  const init = {
    method: method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload)
  }
  return fetch(url,init);
}

class UserLoginForm extends React.Component {
  handleLogin = async e => {
    const loggedIn = await this.props.user.handleLogin(e);
    if(!loggedIn){
      this.props.setMessage("Failed to log in");
    }
  }
  render() {
    let focusedPassword = false;
    let focusedEmail = false;
    const customForms = (
      <Form role="form" onSubmit={this.handleLogin}>
        {this.props.message}
        <FormGroup
          className={classnames("mb-3", {
            focused: this.focusedEmail
          })}
        >
        <InputGroup className="input-group-merge input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-email-83" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Username"
            type="username"
            name="username"
            onFocus={() => focusedEmail = true}
            onBlur={() => focusedEmail = false}
          />
        </InputGroup>
      </FormGroup>
      <FormGroup
        className={classnames({
          focused: focusedPassword
        })}
      >
        <InputGroup className="input-group-merge input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-lock-circle-open" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Password"
            type="password"
            name="password"
            onFocus={() =>
              focusedPassword = true
            }
            onBlur={() =>
              focusedPassword = false
            }
          />
        </InputGroup>
      </FormGroup>
          <div className="text-center">
            <Button className="my-4" color="info" type="submit">
              Sign in
            </Button>
          </div>
        </Form>

    );

    const customLinks = (
      <Row className="mt-3">
        <Col xs="6">
          <ResetPasswordLink {...this.props} />
        </Col>
        <Col className="text-right" xs="6">
        </Col>
      </Row>
    );

    return (
      <UserFormCardWrapper
        cardHeader="Sign in with your Imagine Institute credentials"
        userForms={customForms}
        extraLinks={customLinks}
      />
    )
  }
}

const UserLoginFormWithMessage = withMessage(UserLoginForm);


class ResetPasswordForm extends React.Component {
  state = {
    userEmail: "",
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({[name]:value});
  }

  handleSubmit = async event => {
    event.preventDefault();

    const payload = {mail: this.state.userEmail};
    const response = await jsonRequest("user/lost-password?_format=json", "POST", payload);

    if(response.ok){
      this.props.formSwitch(SetNewPasswordFormWithMessage);
    }else{
      this.props.setMessage("Unable to send reset password email");
    }
  }

  render(){
    const customForms = (
      <form onSubmit={this.handleSubmit}>
        {this.props.message}
        <input 
          required
          placeholder="Email"
          type="text"
          name="userEmail"
          value={this.state.userEmail}
          onChange={this.handleInputChange}
        />
        <div className="text-center">
          <Button className="my-4" color="info" type="submit">
            Reset Password
          </Button>
        </div>
      </form>
    )
    const customLinks = (
      <Row className="mt-3">
        <Col xs="6">
          <UserLoginLink {...this.props} />
        </Col>
        <Col className="text-right" xs="6">
          <CreateUserLink {...this.props} />
        </Col>
      </Row>
    )
    return (
      <UserFormCardWrapper
        cardHeader="Reset your password with your registered email."
        userForms={customForms}
        extraLinks={customLinks}
      />
    )
  }
}

const ResetPasswordFormWithMessage = withMessage(ResetPasswordForm);

class SetNewPasswordForm extends React.Component {
  state = {
    username: "",
    tempPass: "",
    newPass: "",
    confirmNewPass: "",
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({[name]:value});
  }

  handleSubmit = async event => {
    event.preventDefault();

    const payload = {
      name: this.state.username,
      temp_pass: this.state.tempPass,
      new_pass: this.state.newPass,

    };
    const response = await jsonRequest("user/lost-password-reset?_format=json", "POST", payload);

    if(response.ok){
      this.props.formSwitch(UserLoginFormWithMessage);
    }else{
      this.props.setMessage("Unable to reset password.");
    }
  }

  render(){
    const customForms = (
      <form onSubmit={this.handleSubmit}>
        {this.props.message}
        <input
          required
          placeholder="Username"
          type="text"
          name="username"
          value={this.state.username}
          onChange={this.handleInputChange}
        />
        <input 
          required
          placeholder="Temporary Password"
          type="password"
          name="tempPass"
          value={this.state.tempPass}
          onChange={this.handleInputChange}
        />
        <input 
          required
          placeholder="New Password"
          type="password"
          name="newPass"
          value={this.state.newPass}
          onChange={this.handleInputChange}
        />
        <div className="text-center">
          <Button className="my-4" color="info" type="submit">
            Change password
          </Button>
        </div>
      </form>
    )
    const customLinks = (
      <Row className="mt-3">
        <Col xs="6">
          <UserLoginLink {...this.props} />
        </Col>
        <Col className="text-right" xs="6">
          <CreateUserLink {...this.props} />
        </Col>
      </Row>
    )

    return (
      <UserFormCardWrapper
        cardHeader="Use temp password and create a new password"
        userForms={customForms}
        extraLinks={customLinks}
      />
    )
  }
}

const SetNewPasswordFormWithMessage = withMessage(SetNewPasswordForm);

const CreateUserForm = props => {
  return (
    <>
      <p>Create User Form, COMING SOON!</p>
      <UserLoginLink {...props} />
    </>
  )
}

const CreateUserLink = props => {
  return (
    <UserLink
      formSwitch={props.formSwitch}
      linkName="Create User"
      newForm={CreateUserForm}
    />
  )
}

const UserLoginLink = props => {
  return (
    <UserLink
      formSwitch={props.formSwitch}
      linkName="User Login"
      newForm={UserLoginFormWithMessage}
    />
  )
}

const ResetPasswordLink = props => {
  return (
    <UserLink
      formSwitch={props.formSwitch}
      linkName="Forgot Password?"
      newForm={ResetPasswordFormWithMessage}
    />
  )
}

class UserLink extends React.Component {
  handleClick = () => {
    this.props.formSwitch(this.props.newForm)
  }
  render(){
    return (
      <a
        className="text-light"
        href="#"
        onClick={this.handleClick}
      >
        {this.props.linkName}
      </a>
    )
  }
}

const UserFormCardWrapper = props => {
  return (
    <>
      <Card className="bg-secondary border-0 mb-0">
        <CardHeader className="bg-transparent pb-5">
          <div className="text-muted text-center mt-2 mb-3">
            <small>{props.cardHeader}</small>
          </div>
        </CardHeader>
        <CardBody className="px-lg-5 py-lg-5">
          <div className="text-center text-muted mb-4">
            <small></small>
          </div>
          {props.userForms}
        </CardBody>
      </Card>
      {props.extraLinks}
    </>
  )
}

export default class UserForm extends React.Component {
  state = {
    currentForm: UserLoginFormWithMessage,
  }
  handleFormSwitch = newForm => {
    this.setState({currentForm: newForm});
  }
  render(){
    return (
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <this.state.currentForm {...this.props} formSwitch={this.handleFormSwitch}/>
          </Col>
        </Row>
      </Container>
    )
  }
}





