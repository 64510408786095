/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from "react"
import Layout from "./src/components/layout/layout"
import signInToView from "./src/components/user-context"
import Helmet from "react-helmet"
import wrapWithProvider from "./wrap-with-provider"

import "./src/assets/scss/overrides.scss"
import "./src/assets/nucleo/css/nucleo.css"
//import "./src/assets/nucleo/css/nucleo-svg.css"

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it

  const page = (
    <Layout {...props}>{element}</Layout>
  );
  if (props.uri != '/' && !props.hasOwnProperty('user')) {
    return signInToView(page);
  } else {
    return page;
  }
};

export const wrapRootElement = wrapWithProvider;
