/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// Link from reach router
import { Link } from "@reach/router";
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  NavbarBrand,
  Container,
  Breadcrumb as Strapcrumb,
  BreadcrumbItem as StrapcrumbItem
} from "reactstrap";

import {
  siteMetadata
} from "../../../gatsby-config"

import { wrapUserConsumer } from "../user-context"
import LanguageSwitcher from "../header/languageSwitcher";
import defaultUserImage from "../../assets/img/defaultUserImage.png";
import Masquerade from "../masquerade";

function Breadcrumb({ location }) {
  const pathArr = location && location.pathname ? location.pathname.split("/").filter(item => { return item != "" }) : [];
  const childLink = pathArr.length ? pathArr[pathArr.length - 1].replace(/-/g, " ") : ' ';
  // Later if we need a parent link in the breadcrumbs we can do something in this
  // return like pathArr.map(item => <Strapcrumb>item</Strapcrumb>)
  // where we process 'item' like we do childLink above
  return (
    <Strapcrumb 
      className="d-none d-md-inline-block ml-md-4"
      listClassName="breadcrumb-links breadcrumb-dark"
    >
      <StrapcrumbItem> 
        <a href="/dashboard">
          <i className="fas fa-home" />
        </a>
      </StrapcrumbItem>
      <StrapcrumbItem> 
        <a href="#">
          { childLink.charAt(0).toUpperCase() + childLink.slice(1) }
        </a>
      </StrapcrumbItem>
    </Strapcrumb>
  );
}

const UserDropdown = props => {
  const user = props.user && (props.user.initialLoadingComplete || Object.keys(props.user.userData).length !== 0) ? props.user : null;
  let profile = user ? user.userData.profile[Object.keys(user.userData.profile)[0]] : null;
  const image = profile ? profile.included[1] : null;
  const imageUri = image ? image.attributes.uri : null;
  const imageUrl = imageUri ? imageUri.url : null;
  const username = user ? profile.data.attributes.name : null;
  return (
    <Nav className="align-items-center ml-auto ml-md-0" navbar>
      <UncontrolledDropdown nav>
        <DropdownToggle className="nav-link pr-0" color="" tag="a">
          <Media className="align-items-center">
            <span className="avatar avatar-sm rounded-circle">
              <img
                alt="..."
                //eventually add some logic that uses user provided image if provided
                src={imageUrl ? `${siteMetadata.drupalUrl}${imageUrl}` : defaultUserImage}
              />
            </span>
            <Media className="ml-2 d-lg-block">
              <span className="mb-0 text-sm font-weight-bold">
                {username ? username : ''}
              </span>
            </Media>
          </Media>
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem className="noti-title" header tag="div">
            <h6 className="text-overflow m-0">Welcome!</h6>
          </DropdownItem>
          <DropdownItem
            href="/dashboard"
          >
            <i className="ni ni-app" />
            <span>Home</span>
          </DropdownItem>
          <DropdownItem
            href="/profile"
          >
            <i className="ni ni-single-02" />
            <span>Edit profile</span>
          </DropdownItem>
          <DropdownItem
            href="/contact"
          >
            <i className="ni ni-support-16" />
            <span>Support</span>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem
            href="#pablo"
            onClick={props.user && props.user.handleLogout ? props.user.handleLogout : () => {}}
          >
            <i className="ni ni-user-run" />
            <span>Logout</span>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Nav>
  )
}

const UserDropdownWithContext = wrapUserConsumer(UserDropdown);

function AdminNavbar(props) {
  // function that on mobile devices makes the search open
  const openSearch = () => {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function() {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  };
  // function that on mobile devices makes the search close
  const closeSearch = () => {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  };
  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand border-bottom",
          { "navbar-dark bg-info": props.theme === "dark" },
          { "navbar-light bg-secondary": props.theme === "light" }
        )}
      >
        <Container fluid>
          <div className="collapse-brand col-1">
            <NavbarBrand to="/" tag={Link}>
              <img
                alt="..."
                src={
                  require("../../assets/img/imagine-logo-wh.png")
                }
              />
            </NavbarBrand>
          </div>
          <h1 className="h2 text-white d-inline-block mb-0">Imagine Institute</h1>

          <Breadcrumb {...props} />

          <Nav className="align-items-center ml-md-auto" navbar>
            <NavItem className="d-xl-none">
              <div
                className={classnames(
                  "pr-3 sidenav-toggler",
                  { active: props.sidenavOpen },
                  { "sidenav-toggler-dark": props.theme === "dark" }
                )}
                onClick={props.toggleSidenav}
              >
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line" />
                  <i className="sidenav-toggler-line" />
                  <i className="sidenav-toggler-line" />
                </div>
              </div>
            </NavItem>
            <NavItem className="d-sm-none">
              <NavLink onClick={openSearch}>
                <i className="ni ni-zoom-split-in" />
              </NavLink>
            </NavItem>


          </Nav>
          <UserDropdownWithContext />
          <LanguageSwitcher {...props}/>
          <Masquerade />
        </Container>
      </Navbar>
    </>
  );
}
AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark"
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"])
};

export default wrapUserConsumer(AdminNavbar);
