import {
  siteMetadata
} from "../../gatsby-config"
import docCookies from "mozilla-doc-cookies"

import Subrequests from "d8-subrequests"

const axios = require('axios');

export function checkIfDuplicateModification(body){
  return body.hasOwnProperty('errors') &&
    body.errors.length === 1 &&
    body.errors[0].status === "422" &&
    body.errors[0].hasOwnProperty('detail') &&
    body.errors[0].detail ===
    "Entity is not valid: The content has either been modified by another user, or you have already submitted modifications. As a result, your changes cannot be saved.";
}
export default class Auth{
  constructor(){
    this.token = false;
    this.loginUser = this.loginUser.bind(this);
    this.isLoggedIn = this.isLoggedIn.bind(this);

  }

  /**
   *
   * @param resp
   * @returns {Promise<object | boolean>} returns a truthy value if this is a valid response
   */
  async verifyResponse(resp){
    // check match for 2XX status for success
    if(/2../.test(resp.status.toString())){
      // Our access token works
      if(resp.status === 204){
        //No content returned, don't return any json
        return true;
      }
      return await resp.json();
    }
    return false;
  }

  /**
   * @param requests - array of requests with properties according to subrequests docs
   */
  async authSubrequest(requests){
    if(requests){
      const subrequestEndpoint = `${siteMetadata.drupalUrl}/subrequests?_format=json`;
      const subrequest = new Subrequests(subrequestEndpoint); 

      const authHeaders = {
        'Authorization': `Bearer ${this.token.access_token}`,
        'Content-Type':  'application/vnd.api+json'
      };

      const masterRequest = {
        headers: authHeaders
      }

      requests.map(request => {
        subrequest.add(request);
      });

      const subresponseHandler = function(response){
        const parsedResponses = {};
        for( let [key, value] of Object.entries(response.data)){
          if(value.hasOwnProperty('body')){
            parsedResponses[key] = JSON.parse(value.body);
          }
        }
        return parsedResponses;
      }

      let subresponse;
      try{
        subresponse = await axios.get(subrequest.getUrl(), masterRequest);
      }catch(error){
        if(error.response.status == 401 || error.response.status == 403){
          await this.refresh();
          masterRequest.headers.Authorization = `Bearer ${this.token.access_token}`;
          subresponse = await axios.get(subrequest.getUrl(), masterRequest);
        }else{
          throw error;
        }
      }
      return (subresponse.status == 207) ? subresponseHandler(subresponse) : false;
    }
    return false;
  }

  /**
   * Fetch data from a Drupal back end. Expects to be called in a locally logged in context (i.e we have some kind of
   * token). It will try to use the refresh token if it fails via 401 the first time. If it still fails after
   * refreshing, it will return false, signifying that this user should no longer be logged in.
   *
   * @param {string} jsonapiEndpoint An endpoint to retrieve resources using the JSONAPI (https://jsonapi.org/). Includes
   * everything after the host except the leading slash (ex. `'articles?page[offset]=2'`)
   * @param {string} method An HTTP verb, mainly meant for `'GET'`, `'POST'`, `'PATCH'` and `'DELETE'`
   * @param {object|null} body The body of the request, for `'POST'` and `'PATCH'`
   * @param {object} headers
   *
   * @returns {Promise<object | Boolean>} A promise which will return a JSON object of content, true if success with no
   * content, or false if it failed
   */
  async drupalFetch(jsonapiEndpoint, method='GET', body=null, headers=null){

    if(body && !(body instanceof File)){
      body = JSON.stringify(body)
    }

    const url = `${siteMetadata.drupalUrl}/jsonapi/${jsonapiEndpoint}` ;
    const init = {
      method: method,
      headers: {
        'Authorization': `Bearer ${this.token.access_token}`,
        'Content-Type':  'application/vnd.api+json'
      },
    };
    Object.assign(init.headers, headers);
    if(body){
      init.body = body
    }
    const resp = await fetch(url, init);
    const validResponse = await this.verifyResponse(resp);
    if(validResponse){
      return validResponse;
    }

    else if(resp.status === 401 || resp.status === 403){
      // if we get 401 unauthorized (or apparently a 403, since JSONAPI seems to give us this), its probably because our access token is expired.
      // refresh and try again with a new token
      const newToken = await this.refresh();
      if(newToken) {
        // Our refresh token is still good, we've got a fresh token
        init.headers = {
          'Authorization': `Bearer ${newToken.access_token}`,
          'Content-Type':  'application/vnd.api+json'
        };
        Object.assign(init.headers, headers);
        const secondResp = await fetch(url, init);
        // check match for 2XX status for success
        const validResponse = await this.verifyResponse(secondResp);
        if(validResponse){
          return validResponse;
        }
        else{
          /*TODO: Try one more time? We expect to be logged in so either
           something external happened (and we should try again), the expiration on the token is too short,
           or we made a bad request.*/
        }
      }
    }
    else if(resp.status === 422){
      // if we get a duplicate modification error, just ignore it. our API interactions should be designed to avoid them,
      // and if any actually do happen, they should be truly duplicate, meaning that the failed one can be safely discarded
      const body = await resp.json();
      if(checkIfDuplicateModification(body)){
        return body;
      }

    }

    // If everything fails, return false
    return false;
  }

  /**
   * Uses the refresh token stored in the cookie to get a new oauth token.
   * @returns {Promise<object | Boolean>} A token object with `refresh_token` and `access_token`, or false if no
   * existing refresh token or login failed (meaning this user is not logged in)
   */
  async refresh(){
    let refreshToken = false;
    if (typeof window !== `undefined`) {
      refreshToken = docCookies.getItem('imagine_refresh_token');
    } 
    if(typeof refreshToken !== `undefined` && refreshToken){
      return await this.authPost('refresh_token', refreshToken);
    }

    return false
  }

  /**
   * Checks local state to see if we have a token. Run refresh
   * @returns {boolean}
   */
  isLoggedIn(){
    return this.token ? true : false;
  }

  /**
   * Authenticates against a Drupal oauth backend. Will set or unset cookie with refresh token on success or failure.
   *
   * @param grantType Either `'password'` or `'refresh_token'`
   * @param authValue The value of the refresh token, if using `refresh_token` grant type
   * @param FormData object, if you are using the password grant, you should turn your form element into a formData object then pass to authPost. This way you can manipulate the formObject outside of the authPost method.
   * @returns {Promise<object | Boolean>} A token object with `refresh_token` and `access_token`, or false if login
   * failed
   */
  async authPost(grantType, authValue=null, form=null){
    const url = siteMetadata.drupalUrl + '/oauth/token';
    const formData = form ? form : new FormData();
    if(authValue){
      formData.append(grantType, authValue);
    }
    formData.append('grant_type', grantType);
    formData.append('client_id', process.env.GATSBY_DRUPAL_OAUTH_CLIENT_ID); // '1078228c-3064-4011-a944-3c3de2cb70a2'
    const init = {
      method: 'POST',
      body: formData,
    };
    const resp = await fetch(url, init);
    if(resp.status === 200){
      this.token = await resp.json();
      if (typeof window !== `undefined`) {
        docCookies.setItem('imagine_refresh_token', this.token.refresh_token, Infinity, '/');
      }
      return this.token;
    }
    // else if(resp.status === 500){
    //   //service unavailable, trying again
    //   const secondResp = await fetch(url, init);
    //   if(secondResp.status === 200){
    //     this.token = await secondResp.json();
    //     docCookies.setItem('imagine_refresh_token', this.token.refresh_token);
    //     return this.token;
    //   }
    // }
    else{
      if (typeof window !== `undefined`) {
        docCookies.removeItem('imagine_refresh_token');
      }
      return false;
    }
  }
  /**
   * Listens for the form submit of the user login form and initiates an oauth token request with the `password` grant
   *
   * type.
   * @param {Event} event A form submit event
   * @returns {Promise<object | Boolean>} A token object with `refresh_token` and `access_token`, or false if login
   * failed
   */
  async loginUser(event){
    const formData = new FormData(event.target);
    const initialLoginResponse = await this.authPost('password', null, formData);
    //because the authPost method returns false on failure, I can assume if I have anything other
    //than false, then it was successful in some way.
    //So if we successfully log in, lets try and get all of their assigned drupal roles
    if(initialLoginResponse){
      //sort of a work around for finding our user id without having the user context
      const isMe = await this.drupalFetch('/');
      if (typeof isMe.meta !== `undefined` && typeof isMe.meta.links.me !== `undefined`) {
        //query user data and include the roles relationship to save us the additional fetch
        const userData = await this.drupalFetch('user/user/' + isMe.meta.links.me.meta.id + '?include=roles');
        if(userData.hasOwnProperty('included')){
          let scopes = [];
          userData.included.map(include => {
            //we only care about includes of the the user_role type
            if(include.type == 'user_role--user_role'){
              scopes.push(include.attributes.drupal_internal__id);
            }
          });
          if(scopes){
            //We have to use the password grant again because the refresh grant will only allow
            //you to remove scopes, you cannot add new scopes through the refresh grant
            //so we just use the original formData and append the scopes and do a new login.
            formData.append('scope', scopes.join(' '));
            return await this.authPost('password', null, formData);
          }
        }
      }
    }
    //if we failed to login initially or couldn't find any other scopes, return the original response
    return initialLoginResponse;
  }

  async logoutUser(event){
    docCookies.removeItem('imagine_refresh_token', '/');
    //removes all localstorage for page
    localStorage.clear();
    //removes current access tokens/response
    this.token = false;
    return await !docCookies.hasItem('imagine_refresh_token');
  }
}
