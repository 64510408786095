export function generateScheduleTable(eventAnswers) {
  let table = '';
  // Make header row [ Start - End | Title ]
  const header = {attributes: {title: "Event", field_start: "Start", field_end: "End"}};
  // Iterate through eventAnswers to create sorted array by start time
  let eventArray = [];
  for(let eventKey in eventAnswers) {
    eventArray.push(eventAnswers[eventKey]);
  }
  eventArray.sort((a,b) => { 
    return Date.parse(a.attributes.field_start) - Date.parse(b.attributes.field_start) 
  });
  // Iterate through eventArray for item rows
  table += '<table class="schedule-table">';
  table += '<tr><th>' + header.attributes.field_start + '-' + header.attributes.field_end + '</th>'
    + '<th>' + header.attributes.title + '</th></tr>';
  for (let eventObj of eventArray) {
    // if the start/end isn't a frontend loaded timestampe, then we just use the string we got from the backend
    // we also need to multiply by 1000 as the values are divided by 1000 before being stored in userData
    let startVal = typeof eventObj.attributes.field_start !== "number"
      ? eventObj.attributes.field_start : eventObj.attributes.field_start * 1000;
    let endVal = typeof eventObj.attributes.field_end !== "number"
      ? eventObj.attributes.field_end : eventObj.attributes.field_end * 1000;
    let startLocaleString = new Date(startVal).toLocaleString("en-US", {timeZone: "America/Los_Angeles"})
    let endLocaleString = new Date(endVal).toLocaleString("en-US", {timeZone: "America/Los_Angeles"})
    let startTime = new Date(startLocaleString);
    let endTime = new Date(endLocaleString);
    table += '<tr><td>' + _getTime(startTime) + ' - ' + _getTime(endTime) + '</td>' 
     + '<td>' + eventObj.attributes.title + '</td></tr>'; 
  }; 
  table += '</table>';
  return table;
}

function _getTime(dateTime) {
  let hours = dateTime.getHours();
  let minutes = dateTime.getMinutes();
  if (hours > 12) {
    hours -= 12;
  } else if (hours === 0) {
    hours = 12;
  }
  
  if (hours < 10) {
    hours = '0' + hours;
  }

  if (minutes < 10) {
    minutes = '0' + minutes;
  }

  return hours + ':' + minutes;
}
