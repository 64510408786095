import {
  siteMetadata
} from "../../gatsby-config"
import React, {useState} from "react"
import { wrapUserConsumer } from "../components/user-context";
import StartConversation, {ConversationsLink} from "../components/conversations";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  InputGroup,
  InputGroupText,
} from "reactstrap"

//we store the userId of who we're masquerading as to persist
export const MASQUERADE_STORE_KEY = "imagine_masquerade_id";

const Masquerade = props => {
  const user = props.user;
  //dont want isMentor to be state since it is technically derived from the user
  //which is a prop, and we want a single source of truth.
  let isMentor = false;
  const [usernameInput, setUsernameInput] = useState("");
  const [queriedUsers, setQueriedUsers] = useState([]);
  const [masquerading, setMasquerading] = useState(false);

  /*
    The set of localStorage helper functions. Lets me save/retrieve objects
    without having to repeat a lot of code
  */
  function localStorageReadItem(key){
    const storage = localStorage.getItem(MASQUERADE_STORE_KEY);
    if(storage){
      const storageObj = JSON.parse(storage);
      return storageObj[key];
    }
    return storage
  }

  function localStorageWriteItem(key, value){
    const storage = localStorage.getItem(MASQUERADE_STORE_KEY);
    let storageObj = {};
    if(storage){
      storageObj = JSON.parse(storage);
    }
    storageObj[key] = value;
    localStorage.setItem(MASQUERADE_STORE_KEY, JSON.stringify(storageObj));
  }

  function localStorageClear(){
    return localStorage.removeItem(MASQUERADE_STORE_KEY);
  }

  /*
    Needing to persist means we have local storage with masquerade user data. This
    is different than checking a mentor is masquerading currently. 
  */
  function needToPersist() {
    return (localStorageReadItem('userId') !== null && localStorageReadItem('name') !== null);
  }

  /*
    calls the user context method that loads a users data into local storage. This passes in
    a filter of the userId that we want to masquerade as.
  */
  async function loadById(id){
    user.dispatch({
      type: 'beginLoading'
    });
    await user.handleLoadUserData(id);
    user.dispatch({
      type: 'completeLoading'
    });
  }

  /*
    When a mentor is done masquerading as someone, they can click demasquerade. This will
    essentially reset all the state and call the demasquerade method
  */
  function handleDemasquerade(event){
    event.preventDefault();
    setUsernameInput("");
    setQueriedUsers([]);
    demasquerade();
  }

  /*
    The demasquerade method is here to load the original users data and clear the local
    storage removing the userId of who was being masqueraded as. The reason this is
    different than the handleDemasquerade method is because originally this was seperate from
    the UI components.
  */
  function demasquerade(){
    localStorageClear();
    const originalUserId = user.userData.profile[Object.keys(user.userData.profile)[0]].data.id;
    loadById(originalUserId);
    setMasquerading(false);
  }

  /*
    Saves userId and name to local storage for persistance then loads their data.
    Sets masquerading state to true.
  */
  function masquerade(id, name){
    localStorageWriteItem('userId', id);
    localStorageWriteItem('name', name);
    loadById(id);
    setMasquerading(true);
  }

  function handleSubmit(event){
    event.preventDefault();
  }

  async function usernameInputChange(event){
    event.preventDefault();
    const usernameInput = event.target.value;
    setUsernameInput(usernameInput);
    if(usernameInput !== ""){
      const filterField = 'filter[userQuery][condition][path]=name';
      const filterOperator = 'filter[userQuery][condition][operator]=CONTAINS';
      const filterValue = `filter[userQuery][condition][value]=${usernameInput}&page[limit]=10`
      const endpoint = `user/user?${filterField}&${filterOperator}&${filterValue}`
      const content = await user.fetchAuthenticatedContent(endpoint);
      setQueriedUsers(content.data);
    }else{
      setQueriedUsers([]);
    }
  }

  function usernameSelect(event){
    event.preventDefault();
    const id = event.target.id;
    const name = event.target.name;
    setQueriedUsers([]);
    setUsernameInput("");
    masquerade(id, name);
  }

  function resetState(){
    setQueriedUsers([]);
    setUsernameInput("");
    setMasquerading(false);
  }

  //would be nice if we made some api in the usercontext to access data such as their roles
  if(user){
    if(user.initialLoadingComplete){
      if(user.hasOwnProperty('userData')){
        const userData = user.userData;
        if(userData.hasOwnProperty('profile')){
          let profile = userData.profile[Object.keys(user.userData.profile)[0]];
          if(profile.hasOwnProperty('included')){
            profile.included.map(include => {
              if(include.type == 'user_role--user_role' && include.attributes.drupal_internal__id == 'mentor'){
                isMentor = true;
              }
            });
          }
        }
      }
    }
  }

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  if(isMentor){
    let demasqueradeButton = null;

    //if they're NOT masquerading already but they need to persist who they're masquerading as
    //then we call masquerade, which will set them to be masquerading and persist the data.
    if(needToPersist() && !masquerading){
      masquerade(localStorageReadItem('userId'), localStorageReadItem('name'));
    }

    let displayComponent = '';
    if(masquerading){
      demasqueradeButton = <Button size="sm" color="primary" onClick={handleDemasquerade}>Reset</Button>;
      displayComponent = (
        <div>
          <Container>
            <Row>
              <StartConversation userName={localStorageReadItem('name')} userId={localStorageReadItem('userId')} />
              <Col xs="auto" className="text-center my-auto px-1">
                <InputGroup>
                  <InputGroupText>{localStorageReadItem('name')}-{demasqueradeButton}</InputGroupText>
                </InputGroup>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }else{
      const dropdownStatus = queriedUsers.length <= 0 ? "d-none" : "";
      displayComponent = (
        <div className="mentor-menu">
          <Container>
            <Row>
              <StartConversation disabled={true} userName={localStorageReadItem('name')} userId={localStorageReadItem('userId')} />
              <Col xs="auto" className="text-center my-auto px-1">
                <Dropdown isOpen={true} active toggle={toggle} inNavbar={true}>
                  <Input placeholder="Masquerade as:" autoComplete="off" type="text" name="username" value={usernameInput} onChange={usernameInputChange}/>
                  <DropdownMenu className={dropdownStatus} active>
                    {queriedUsers.map(user => (
                        <DropdownItem name={user.attributes.name} id={user.id} onClick={usernameSelect}>{user.attributes.name}</DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
    return(
      <div>
        {displayComponent}
      </div>
    );
  }else{
    return (
      <div className="mx-2">
        <ConversationsLink />
      </div>
    );
  }

}

export default wrapUserConsumer(Masquerade);
