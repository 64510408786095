import React from "react"
// reactstrap components
/**
 * import {
 * // UncontrolledCollapse is a great band name
 * // HAh true
 *   UncontrolledCollapse,
 * } from "reactstrap";
 */

import AuthHeader from "./authHeader.js"
import ProfileHeader from "./profileHeader.js"
import GeneralHeader from "./GeneralHeader.js"
import IndexHeader from "./IndexHeader.js"

function Header(props) {
  const uri = props.location ? props.location.pathname : ''
  // If the user is not logged in, it is safe to assume we're returning an AuthHeader
  if ( typeof props.user !== `undefined` && !props.user.isAuthenticated && props.user.userData && Object.keys(props.user.userData).length === 0) {
    return (
      <AuthHeader
        title="Welcome!"
        lead="Imagine Institute's Policy Builder"
      />
    )
  }
  switch(uri) {
    case '/profile/':
      // This is just a proof of concept, exactly identical to GeneralHeader
      // But if we wanted to have specific headers for specific pages we could
      return (
        <ProfileHeader />
      )
    case '/login/':
      return (
        <AuthHeader
          title="Welcome!"
          lead="Imagine Institute's Policy Builder"
        />
      )
    case '/':
      return (
        <IndexHeader />    
      )
    default:
      return (
        <GeneralHeader />
      )
  }
}

export default Header
