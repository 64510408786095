import { createStore as reduxCreateStore, combineReducers, applyMiddleware, compose } from "redux"
import * as FlexWebChat from "@twilio/flex-webchat-ui";

const reducers = combineReducers({
    flex: FlexWebChat.WebchatReducer
});

const middleware = applyMiddleware();

const createStore = () => reduxCreateStore(reducers, FlexWebChat.applyWebchatMiddle ? compose(middleware, FlexWebChat.applyWebchatMiddleware()) : middleware)
export default createStore
