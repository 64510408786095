import React from "react";
import {
  Button,
  Modal,
  Label,
} from "reactstrap";
import { graphql, useStaticQuery } from "gatsby";

export default function Disclaimer({user}){
  if(user && !user.initialLoadingComplete){
    return <></>;
  }
  const data = useStaticQuery(graphql`
    query DisclaimerQuery {
      allNodeDisclaimer {
        edges {
          node {
            field_accept_text
            body {
              processed
            }
          }
        }
      }
    }
  `);
  let profile = user && user.userData && user.userData.profile ? user.userData.profile[Object.keys(user.userData.profile)[0]] : null;
  function handleAccept(){
    profile.data.attributes.field_disclaimer_accepted = true;
    user.fetchAuthenticatedContent(`user/user/${profile.data.id}`, 'PATCH', profile);
    localStorage.setItem("imagine_user_data", JSON.stringify(user.userData));
    user.dispatch({
      type: 'updateUserData',
      payload: {
        userData: user.userData
      }
    });
  }
  return (
    <Modal isOpen={profile && !profile.data.attributes.field_disclaimer_accepted}>
      <div className="modal-body" dangerouslySetInnerHTML={{ __html: data.allNodeDisclaimer.edges[0].node.body.processed}} />
      <div className="modal-footer">
        <Button
          color="primary"
          onClick={handleAccept}
        >
          { data.allNodeDisclaimer.edges[0].node.field_accept_text }
        </Button>
      </div>
    </Modal>
  );
}
