import React, { useEffect } from "react"
import {BounceLoader} from "react-spinners"

const ImagineSpinner = props => {
  useEffect(() => {
    return function cleanup() {
      let elem = document.querySelector('.d-flex.justify-content-center');
      if (elem) {
        let classList = elem.classList;
        classList.remove('d-flex');
        classList.remove('justify-content-center');
      }
    }
  });
  return (
    <div className="d-flex justify-content-center">
      <BounceLoader color="#077AE0" size="100px" />
    </div>
  )
}

export default ImagineSpinner;
