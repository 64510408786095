import React from "react"
import classnames from "classnames"
import {
  Link,
  StaticQuery,
  graphql,
  navigate
} from "gatsby"

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from "reactstrap"

import docCookies from "mozilla-doc-cookies"

import { wrapUserConsumer } from "../user-context"

function buildPathContext(path, languages, defaultLangcode){

  /*
    A lot of this works under the assumption that we will only have langcodes in the path
    if it's in the very beginning. And otherwise no langcodes should be in the path.
    for example:
    /en/some-path/more-path --- OK
    /some-path/en/more-path --- NOT OK
    en/some-path/more-path --- OK
    /some-path/more-path --- OK
  */

  const langs = languages.map( lang => ( lang.langcode ) );
  //split path by / tokens. Remove empty tokens
  const pathTokens = path.split("/").filter(token => token.length > 0);
  //checks if the languages array includes the first token in pathTokens, if it is then we
  //assume that's the current language. Otherwise we use the default language.
  const currentLang = (langs.includes(pathTokens[0])) ? pathTokens[0] : defaultLangcode;
  let currentLangname = null;
  languages.forEach( lang => {
    if(lang.langcode === currentLang){
      currentLangname = lang.langname;
    }
  });

  const pathHasLangcode = langs.some(lang => pathTokens.indexOf(lang) >= 0);
  //removes the first token if it's a langcode
  const nonTranslatedTokens = (pathHasLangcode) ? pathTokens.slice(1) : pathTokens.slice(0);
  //rebuilds the path without the langcode so we can just use this to build all the other paths
  const nonTranslatedPath = "/" + nonTranslatedTokens.join("/");

  let langLinks = {};
  languages.forEach(lang => {

    let newLink = null;

    if(lang.langcode !== defaultLangcode){
      newLink = "/" + lang.langcode + nonTranslatedPath;
    }else{
      newLink = nonTranslatedPath;
    }
    langLinks[lang.langcode] = { path: newLink, langname: lang.langname }
  });

  return {
    path: path,
    langcode: currentLang,
    langname: currentLangname,
    translations: {
      ...langLinks
    }
  }
}

class LanguageLink extends React.Component {
  handleClick = () => {
    docCookies.setItem("imagine_session_langcode", this.props.newLang, "Infinity", "/");
  }
  render() {
    return (
      <Link
        replace
        to={this.props.linkPath}
        onClick={this.handleClick}
        state={{stateLangcode: this.props.newLang}}
      >
        <i className="ni ni-bold-right"></i>
        <span>{this.props.linkName}</span>
      </Link>
    )
  }
}

const LanguageDropdown = props => {
  return (
    <UncontrolledDropdown className="lang-switcher">
      <DropdownToggle className="btn btn-white btn-sm">
        {props.menuName}
      </DropdownToggle>
      <DropdownMenu right>
        <div className="noti-title dropdown-header" tabIndex="-1">
          <h6 className="text-overflow m-0">Select Language</h6>
        </div>
        {props.children}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

const languageSwitcher = props => {
  return (
    <StaticQuery
      query={graphql`
        query langQuery {
          site {
            siteMetadata {
              langs {
                defaultLangcode
                langs{
                  langcode
                  langname
                }
              }
            }
          }
          allSitePage {
            edges {
              node {
                path
              }
            }
          }
        }
      `}
      render={data => {

        const validPaths = data.allSitePage.edges.map(edge => {return edge.node.path});
        const defaultLangcode = data.site.siteMetadata.langs.defaultLangcode;
        const langsData = data.site.siteMetadata.langs.langs;
        const langs = langsData.map( lang => ( lang.langcode ) );

        //just generates a bunch of data for us and stores it in an object about the path
        const pathContext = buildPathContext(props.location.pathname, langsData, defaultLangcode);

        //get langcode from all the sources we want to check
        const stateLangcode = (props.location.state) ? props.location.state.stateLangcode : null;
        const cookieLangcode = typeof window !== `undefined` ? docCookies.getItem('imagine_session_langcode') : 'en';


        let userData = null;
        if( typeof props.user !== 'undefined' ){
          userData = props.user.initialLoadingComplete ? props.user.userData : null;
        }

        let userLang = null;
        if( userData ){
          userLang = userData.profile[Object.keys(userData.profile)[0]] ? userData.profile[Object.keys(userData.profile)[0]].data.attributes.preferred_langcode : null;
        }

        //coalesce all the language sources to find the one we want
        const desiredLang = cookieLangcode || stateLangcode || userLang || defaultLangcode;

        //need to redirect if we're not on the correct page and the desired one exists
        if(typeof window !== `undefined` && desiredLang !== pathContext.langcode && langs.indexOf(desiredLang) !== -1){
          navigate(pathContext.translations[desiredLang].path, { replace: true });
        }

        let transLinks = [];
        //iterate over all available translations
        for( var translation in pathContext.translations ){
          const transData = pathContext.translations[translation]
          const transPath = transData.path;
          const transName = transData.langname;
          //if current iteration translation is the same as the current language 
          //then we don't want to render a link to switch to it.
          //We also want to check that the generated path is a valid path, if not
          //we also don't want to render a link to switch to it.
          if( translation !== pathContext.langcode && validPaths.includes(transPath) ){
            const newLink = <DropdownItem key={transName}>
                              <LanguageLink
                                newLang={translation}
                                linkPath={transPath}
                                linkName={transName}
                              />
                            </DropdownItem>
            transLinks.push(newLink);
          }
        }

        //need to handle the case when there are no translations. I'm thinking we just
        //don't render the language switcher
        if(transLinks.length){
          return (
            <LanguageDropdown menuName={pathContext.langname}>
              {transLinks}
            </LanguageDropdown>
          )
        }
      }}
    />
  )
}

export default wrapUserConsumer(languageSwitcher);
