/*!

=========================================================
* Imagine Institute - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import { Link } from "@reach/router";
// reactstrap components
import { Button, Card, CardBody, Container, Row, Col } from "reactstrap";

function IndexHeader(props) {
  return (
    <>
      <div className="header bg-gradient-info pt-5 pb-7">
        <Container>
          <div className="header-body">
            <Row className="align-items-center">
              <Col lg="6">
                <div className="pr-5">
                  <h1 className="display-2 text-white font-weight-bold mb-0">
                    Policy Wizard
                  </h1>
                  <h2 className="display-4 text-white font-weight-light">
                    Powered by the Imagine Institute
                  </h2>
                  <p className="text-white mt-4">
                    It has never been faster or easier to create tailored program policies for your unique child care.
                  </p>
                  <div className="mt-5">
                    <Button
                      className="btn-neutral my-2"
                      color="default"
                      to="/admin/dashboard"
                      href="https://imaginewa.org"
                    >
                      Explore
                    </Button>
                    <Button
                      className="my-2"
                      color="default"
                      href="https://imaginewa.org"
                    >
                      Sign up
                    </Button>
                  </div>
                </div>
              </Col>
              <Col lg="6">
                <Row className="pt-6">
                  <Col className="pt-lg-3 pt-1" md="6">
                    <Card className="mb-4">
                      <CardBody>
                        <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow mb-4">
                          <i className="ni ni-active-40" />
                        </div>
                        <h5 className="h3">We’ll help you each step of the way</h5>
                        <p>
                          Policy Wizard asks you questions to determine which policies your program needs.
                        </p>
                      </CardBody>
                    </Card>
                    <Card className="mb-4">
                      <CardBody>
                        <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow mb-4">
                          <i className="ni ni-active-40" />
                        </div>
                        <h5 className="h3">You make the rules</h5>
                        <p>
                          Instead of standardized templates, Policy Wizard works interactively to find out what matters most to you.
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="pt-lg-5 pt-4" md="6">
                    <Card className="mb-4">
                      <CardBody>
                        <div className="icon icon-shape bg-gradient-success text-white rounded-circle shadow mb-4">
                          <i className="ni ni-active-40" />
                        </div>
                        <h5 className="h3">Endless customization</h5>
                        <p>Once you complete the Policy Wizard, you will be able to download your policies as Word document.
                        </p>
                      </CardBody>
                    </Card>
                    <Card className="mb-4">
                      <CardBody>
                        <div className="icon icon-shape bg-gradient-warning text-white rounded-circle shadow mb-4">
                          <i className="ni ni-active-40" />
                        </div>
                        <h5 className="h3">Additional resources</h5>
                        <p>
                          We embed links to helpful resources so terms and references are easy to define and locate.
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default IndexHeader;
