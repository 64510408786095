/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { createContext, useState, useCallback, isValidElement, useEffect } from "react";
// reactstrap components
import {
  Button,
  Modal
} from "reactstrap";

import { navigate } from "gatsby";
import '../argon-dashboard-pro-react-v1.0.0/src/assets/scss/argon-dashboard-pro-react.scss';
import '../argon-dashboard-pro-react-v1.0.0/src/assets/vendor/animate.css/animate.min.css';
import NotificationAlert from "react-notification-alert";

export const NotifyContext = createContext();

export function Notification({ title, messages }){
  const messageList = [];
  messages = Array.from(messages);
  for(let [key, message] of messages.entries()){
    messageList.push(
      <li key={key}>{message}</li>
    );
  }
  let messageEl = (
    <ul data-notify="message">
      { messageList }
    </ul>
  );
  if(messages.length === 1){
    messageEl = (
      <span>
        { messages[0] }
      </span>
    );
  }
  return (
    <div className="alert-text">
      <span className="alert-title" data-notify="title">
        { title }
      </span>
      { messageEl }
    </div>
  );
}

/**
 *
 * @param {string} to
 * @param {object} notification
 * @param {object} state
 * @param {bool} replace
 */
export function navigateAndNotify(to, notification, state = {}, replace = null){
  function uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  notification.id = uuidv4();
  state.state = {
    notification: notification
  };
  if(replace !== null){
    navigate(to, state, replace);
  }
  else{
    navigate(to, state);
  }
}
// we use this defer to write a notify function that waits for the ref callback to complete before calling the function
// on the ref
function defer() {
  let res, rej;

  const promise = new Promise((resolve, reject) => {
    res = resolve;
    rej = reject;
  });

  promise.resolve = res;
  promise.reject = rej;

  return promise;
}

const deferredAlertObj = defer();

export function NotificationWrapper(props){
  // to avoid calling the alert when nothings changed, keep track of previous messages and only change when they do
  //const [prevAlerts, setPrevAlerts] = useState([]);
  async function notifyFunc(options, clear = true){
    const alertRef = await deferredAlertObj;
   /* if(options && !isValidElement(options.message)){
      for(let prevAlert of prevAlerts){
        console.log("prev and cur", options, prevAlert);
        if(isValidElement(prevAlert.message) || prevAlert.message.messages.size !== options.message.messages.size){
          continue;
        }
        let sameMessages = true;
        for(let msg of options.message.messages){
          if(!prevAlert.message.messages.has(msg)){
            sameMessages = false;
          }
        }
        if(prevAlert.place === options.place && prevAlert.title === options.title && sameMessages){
          return;
        }
      }
    }
    let nextAlerts = prevAlerts;*/
    if(clear){
      for(let place of ['tl', 'tc', 'tr', 'bl', 'bc', 'br']){
        const ns = document.querySelectorAll(`.rna-wrapper .${place}`);
        for(let i = 0; i < ns.length; i++){
          alertRef.onDismiss(i, place, true);
        }
      }
      //nextAlerts = [];
    }
    if(options){
      if(!isValidElement(options.message)){
        // we must accept a non-element here because we need to pass these options through location state which cannot
        // accept React elements. Thus we use our prebuilt Notification component instead.
        options.message = Notification(options.message);
      }
      options.message = (
        <span className={options.place}>
          { options.message }
        </span>
      );
      alertRef.notificationAlert(options);
      //nextAlerts.push(options);
    }
    //setPrevAlerts(nextAlerts);
  }
  // a callback that runs once the ref to react-notification-alert is set. it signals to the notifyFunc that the ref is
  // ready to be used, then uses the API exposed by react-notification-alert.
  const alertCallback = useCallback((alert) => {
    if (alert) {
      alert.refs.notifications.classList.add("rna-wrapper");
      deferredAlertObj.resolve(alert);
    }
  }, []);
  /** TODO: the commented out code below allows handles notifications which have been passed via redirect. However, the
    notifiedAlready state, which prevents duplicate notifications on re-render, also prevents new notifications
    from showing up if the user triggers another notification before state is cleared. This problem seems to be
    avoided if this code is placed within the page it is used, so that is how it currently is. It would be much
   neater if we could defeat this problem and keep the code in here, automatically handling redirect notifications for
   all pages.
   **/
  const [notifiedAlready, setNotifiedAlready] = useState({});
  useEffect(() => {
    (async function sendRedirectAlert(){
      await notifyFunc(null);
      if(props.location && props.location.state && props.location.state.notification){
        const id = props.location.state.notification.id;
        if(!notifiedAlready.hasOwnProperty(id)){
          notifyFunc(props.location.state.notification);
          notifiedAlready[id] = true;
          setNotifiedAlready(notifiedAlready);
        }
      }
    })();
  }, [props.location]);
  return (
    <>
      <NotificationAlert ref={alertCallback} className="imagine-notification" />
      <NotifyContext.Provider value={notifyFunc}>
        {props.children}
      </NotifyContext.Provider>
    </>
  )
}
export class ModalButton extends React.Component {
  state = {
    highlightModal: false,
    notificationModal: false,
    formModal: false,
    alert: null
  };
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };
  render() {
    switch (this.props.type) {
      case "highlight":
        return (
          <>
            <span
              className="highlight-term btn-link"
              onClick={() => this.toggleModal("highlightModal")}
            >
              {this.props.title}
           </span>
           <Modal
             className="modal-dialog-centered"
             isOpen={this.state.highlightModal}
             toggle={() => this.toggleModal("highlightModal")}
           >
             <div className="modal-header">
               <h6 className="modal-title" id="modal-title-default">
                 {this.props.title}
               </h6>
               <button
                 aria-label="Close"
                 className="close"
                 data-dismiss="modal"
                 type="button"
                 onClick={() => this.toggleModal("highlightModal")}
               >
                 <span aria-hidden={true}>×</span>
               </button>
             </div>
             <div className="modal-body">
               {this.props.body}
             </div>
             <div className="modal-footer">
               <Button
                 className="ml-auto"
                 color="link"
                 data-dismiss="modal"
                 type="button"
                 onClick={() => this.toggleModal("highlightModal")}
               >
                 Close
               </Button>
             </div>
           </Modal>
         </>
       )
    
      case "notification":
        return (
          <>
            <Button
              block
              className="mb-3"
              color="warning"
              onClick={() => this.toggleModal("notificationModal")}
            >
              {this.props.title}
            </Button>
            <Modal
              className="modal-dialog-centered modal-danger"
              contentClassName="bg-gradient-danger"
              isOpen={this.state.notificationModal}
              toggle={() => this.toggleModal("notificationModal")}
            >
            <div className="modal-header">
              <h6
                className="modal-title"
                id="modal-title-notification"
              >
                {this.props.title}
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() =>
                  this.toggleModal("notificationModal")
                }
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="py-3 text-center">
                <i className="ni ni-bell-55 ni-3x" />
                {this.props.body}
              </div>
            </div>
            <div className="modal-footer">
              <Button
                className="btn-white"
                color="default"
                type="button"
              >
                Ok, Got it
              </Button>
              <Button
                className="text-white ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() =>
                  this.toggleModal("notificationModal")
                }
              >
                Close
              </Button>
            </div>
          </Modal>
        </>
      )

      default:
        return (
          <>
            <Button
              block
              className="mb-3"
              color="primary"
              onClick={() => this.toggleModal("defaultModal")}
            >
              Default
            </Button>
            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.defaultModal}
              toggle={() => this.toggleModal("defaultModal")}
            >
              <div className="modal-header">
                <h6 className="modal-title" id="modal-title-default">
                  Type your modal title
                </h6>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("defaultModal")}
                >
                  <span aria-hidden={true}></span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Far far away, behind the word mountains, far from
                  the countries Vokalia and Consonantia, there live
                  the blind texts. Separated they live in
                  Bookmarksgrove right at the coast of the Semantics,
                  a large language ocean.
                </p>
                <p>
                  A small river named Duden flows by their place and
                  supplies it with the necessary regelialia. It is a
                  paradisematic country, in which roasted parts of
                  sentences fly into your mouth.
                </p>
              </div>
              <div className="modal-footer">
                <Button color="primary" type="button">
                  Save changes
                </Button>
                <Button
                  className="ml-auto"
                  color="link"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("defaultModal")}
                >
                  Close
                </Button>
              </div>
            </Modal>
          </>
        )
      }
    }
}

