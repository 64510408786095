import { locateEntityInData } from "../components/user-context";

export function getEventsFromUser(user, eventTypes){
  function parseTime(time){
    return Number.isInteger(time) ?
      new Date(time * 1000) :
      new Date(time);
  }
  if(!user.userData.eventAnswers){
    return [];
  }

  return Object.values(user.userData.eventAnswers).map((eventAnswer) => {
    const start = parseTime(eventAnswer.attributes.field_start);
    const end = parseTime(eventAnswer.attributes.field_end);
    const event = {
      title: eventAnswer.attributes.title,
      start: start,
      end: end,
      id: eventAnswer.id ? eventAnswer.id : eventAnswer.local_id,
      editable: true,
      overlap: false,
    };
    if(eventAnswer.relationships &&
      eventAnswer.relationships.field_event_type &&
      eventAnswer.relationships.field_event_type.data){
      for(let [eventType, id] of Object.entries(eventTypes)){
        if(eventAnswer.relationships.field_event_type.data.id === id){
          event.extendedProps = {
            eventType: eventType
          };

          if(eventType === 'Play Time'){
            event.className = 'bg-primary';
          }
          else if(eventType === 'Snack/Meal Time'){
            event.className = 'bg-warning';
          }
        }
      }
    }
    return event;
  });
}

export function convertToMsTime(timeInput, add24hours=false){
  let msSinceMidnite =  timeInput.split(":").reduce((acc, val, idx) => {
    return acc + (parseInt(val) * Math.pow(60, 2 - idx));
  }, 0) * 1000;
  if(add24hours){
    msSinceMidnite += 60 * 60 * 24 * 1000;
  }
  return new Date('01-01-1970').getTime() + msSinceMidnite;
}

export function getScheduleIssues(user, eventTypes, question){
  const answer = user.userData.answers[question.drupal_id];
  const closeNextDay = answer && answer.attributes && answer.attributes.field_close_next_day;
  let [openTimeString, closeTimeString] = getBusinessHourInputVals(answer);
  const openTime  = convertToMsTime(openTimeString);
  const closingTime = convertToMsTime(closeTimeString, closeNextDay); // You don't have to go home but you can't stay here.
  const events = getEventsFromUser(user, eventTypes);
  const issues = {};
  let timeSinceLastSnackMeal = 0;
  let lastSnackMealEvent;

  let playTime = 0;

  function createIssueEvent(start, end){
    return {
      start: start,
      end: end,
      className: 'bg-danger',
      rendering: 'background',
    }
  }

  const businessHours =  Math.floor((closingTime - openTime) / 3600000);
  let minimumPlayTime = businessHours >= 6 ? 3600000 : 0;
  let lastEventEndTime = openTime;
  let overdueSnackMealEvent;
  events.sort((a, b) => a.start.getTime() - b.start.getTime());

  const unscheduledTimeMessage = "Unscheduled time", unscheduledTimeKey = "unscheduled-time";
  const overdueSnackMealMessage = "Gap between snack/meal times too long.", overdueSnackMealKey = "overdue-snack-meal";
  const tooSoonSnackMealMessage = "Snack/meal time too soon after the last one", tooSoonSnackMealKey = "too-soon-snack-meal";
  const notEnoughPlayMessage = "More play time needed", notEnoughPlayKey = "not-enough-play";
  // this loop finds all the violations in the schedule
  for(let event of events.concat({ start: new Date(closingTime) })){
    if(event.start.getTime() > lastEventEndTime){
      if(!issues[unscheduledTimeKey]){
        issues[unscheduledTimeKey] = {
          events: [],
          shortMessage: unscheduledTimeMessage,
          detailMessage: "You have unscheduled gaps. Please schedule something during all business hours (see highlighted areas).",
        };
      }
      issues[unscheduledTimeKey].events.push(createIssueEvent(new Date(lastEventEndTime), event.start));
    }

    timeSinceLastSnackMeal += event.start.getTime() - lastEventEndTime;
    if(timeSinceLastSnackMeal > 1000 * 60 * 60 * 3){
      // more than three hours since last snack/meal time
      if(!issues[overdueSnackMealKey]){
        issues[overdueSnackMealKey] = {
          events: [],
          shortMessage: overdueSnackMealMessage,
          detailMessage: "You have a gap between snack/meal times of larger than three hours (see highlighted areas).",
        };
      }
      if(!overdueSnackMealEvent){
        overdueSnackMealEvent = createIssueEvent(lastSnackMealEvent ? lastSnackMealEvent.end : new Date(openTime));
      }
    }
    if(event.extendedProps && event.extendedProps.eventType && event.extendedProps.eventType === 'Snack/Meal Time'){
      if(overdueSnackMealEvent){
        overdueSnackMealEvent.end = event.start;
        issues[overdueSnackMealKey].events.push(overdueSnackMealEvent);
        overdueSnackMealEvent = null;
      }
      if(timeSinceLastSnackMeal < 1000 * 60 * 60 * 2 && lastSnackMealEvent){
        if(!issues[tooSoonSnackMealKey]){
          issues[tooSoonSnackMealKey] = {
            events: [],
            shortMessage: tooSoonSnackMealMessage,
            detailMessage: 'You have a gap between snack/meal times of shorter than two hours (see highlighted areas).'
          }
        }
        issues[tooSoonSnackMealKey].events.push(createIssueEvent(lastSnackMealEvent.end, event.start));
      }
      lastSnackMealEvent = event;
      timeSinceLastSnackMeal = 0;
    }
    else if(event.end){
      // only add the duration of this event to the time since last snack/meal if this event isn't a snack/meal time
      timeSinceLastSnackMeal += event.end.getTime() - event.start.getTime();
    }
    if(event.extendedProps && event.extendedProps.eventType && event.extendedProps.eventType === 'Play Time'){
      playTime += event.end.getTime() - event.start.getTime();
    }

    if(event.end){
      lastEventEndTime = event.end.getTime();
    }
  }
  // you might have an overdue snack/meal that runs until the end of the day. so you need to add it to the issues at the end of the loop
  if(overdueSnackMealEvent){
    overdueSnackMealEvent.end = new Date(closingTime);
    issues[overdueSnackMealKey].events.push(overdueSnackMealEvent);
    overdueSnackMealEvent = null;
  }

  if(playTime < minimumPlayTime){
    const minimumPlayTimeHours = minimumPlayTime % 3600000 === 0 ? minimumPlayTime / 3600000 : (minimumPlayTime / 3600000).toFixed(2);
    const playTimeHours = playTime % 3600000 === 0 ? playTime / 3600000 : (playTime / 3600000).toFixed(2);
    const hoursWord = minimumPlayTimeHours === 1 ? "hour" : "hours";

    issues[notEnoughPlayKey] = {
      events: [],
      shortMessage: notEnoughPlayMessage,
      detailMessage: `You need at least ${minimumPlayTime / 3600000} ${hoursWord} of play time. So far you have ${playTimeHours}.`,
    };
  }
  return issues;
}

export function getBusinessHourInputVals(answer){
  let openTimeString = "06:00:00", closeTimeString = "18:00:00";
  function getOpenOrCloseTimeString(openOrClose){
    let timeString = openOrClose === 'open' ? openTimeString : closeTimeString;
    const fieldName = `field_${openOrClose}`;
    if(!answer || !answer.attributes[fieldName]){
      return timeString;
    }

    let field = answer.attributes[fieldName];

    if(Number.isInteger(field)){
      field *= 1000;
    }
    const datetime = new Date(field);
    timeString = ['getHours', 'getMinutes', 'getSeconds'].map((timeFuncName) => {
      return datetime[timeFuncName]().toString().padStart(2, '0');
    }).join(":");
    return timeString;
  }

  return ['open', 'close'].map(getOpenOrCloseTimeString);
}

export function hasRequirementsFulfilled(userData, question) {
  // if a question doesn't support requirements, it's fulfilled by default
  if (!question.hasOwnProperty('relationships') ||
    typeof question.relationships.field_requirements === `undefined`){
    return true;
  }

  // in order to support a chain of requirements, such as when:
  //  - Question A requires an option in Question B
  //  - Question B requires an option in Question C and an option in Question D
  // to verify the requirements Question A are fulfilled
  // we need to make sure that *not only* the option in Question B has been selected,
  // but also the options in Questions C and D

  // each iteration of the `do` loop corresponds to one "generation" of requirements (in the variable `reqs`),
  // in this case,
  // - first the requirements of Question A,
  // - then the requirements of the parent, Question B,
  // - then the requirements of the grandparents, Questions C and D
  // then it should terminate, as C and D have no further requirements
  // if any requirements are missing, terminate early, we have not fulfilled one or more
  let reqs = question.relationships.field_requirements;
  do{
    let nextReqs = [];

    // loop through a generation of requirements
    for (let req of reqs){
      let reqId;
      if(req.hasOwnProperty('drupal_id')){
        // the first generation comes from the gatsby query and will have a drupal_id
        reqId = req.drupal_id;
      }
      else{
        // subsequent generations will come from the user context requests and their ID is the Drupal ID
        reqId = req.id;
      }

      // search through our user data to find an answer which fulfills this requirement
      // let requiredAnswer;
      // for(let answer of Object.values(userData.answers)){
      //   if(answer.hasOwnProperty('relationships') && answer.relationships.)
      // }
      let requiredAnswer = locateEntityInData(userData,
        'answers.relationships.field_answer_option.data.id'.split('.'),
        reqId, 'node--select_answer');

      if (requiredAnswer){
        // we found an answer which fulfills this requirement, get the question for that answer
        let questionRef = requiredAnswer.relationships.field_question_reference.data.id;
        let requiredQuestion = userData.hasOwnProperty('answeredQuestions') &&
          userData.answeredQuestions.hasOwnProperty(questionRef) ?
          userData.answeredQuestions[questionRef] : null;

        // add all of that question's requirements to the next generation
        if(requiredQuestion &&
          requiredQuestion.hasOwnProperty('relationships') &&
          requiredQuestion.relationships.hasOwnProperty('field_requirements')){
          for(let nextReq of requiredQuestion.relationships.field_requirements.data){
            nextReqs.push(nextReq);
          }
        }
      }
      else{
        // we did not find an answer which fulfills this requirement, terminate early
        return false;
      }
    }
    // set up the next generation for iteration
    reqs = nextReqs;

    // if this generation did not produce any further requirements,
  } while(reqs.length > 0);
  //   we are done and this question has fulfilled all its requirements.
  return true;
}

export function checkAllSectionsComplete(sections) {
  for (let section of sections) {
    if (section.status !== 'completed' && section.status !== 'not required') {
      return false;
    }
  }
  return true;
}

/**
 *
 * @param userData
 * @param chapterData
 * @returns {boolean}
 */
export function checkChapterComplete(userData, chapterData){
  let extractStatus = (section) => {
    return {
      status: getSectionStatus(userData, section)
    };
  };
  const sections = chapterData.relationships.field_sections.map(extractStatus);

  let chapterComplete = checkAllSectionsComplete(sections);

  let chapterReviewSection =  chapterData.relationships.hasOwnProperty('field_chapter_review_section') ?
    chapterData.relationships.field_chapter_review_section : false;

  if( chapterComplete
      && chapterReviewSection){
    sections.push(extractStatus(chapterReviewSection));
    // recompute chapter complete with the chapter review section included
    chapterComplete = checkAllSectionsComplete(sections);
  }

  return chapterComplete;
}

/**
 *
 * @param section
 * @param userData
 * @returns {string}
 */
export function getSectionStatus(userData, section){
  let started = false,
    completed = true;

  // questions which are not visible are not considered for the section status
  const questions = section.relationships.field_questions.filter(hasRequirementsFulfilled.bind(null, userData));
  if(questions.length === 0){
    return 'not required';
  }
  for(let question of questions){
    if(userData.hasOwnProperty('answers') && userData.answers.hasOwnProperty(question.drupal_id) && userData.answers[question.drupal_id].hasOwnProperty('type') && userData.answers[question.drupal_id].type === "node__schedule_question"){
      if(userData.eventAnswers && Object.entries(userData.eventAnswers).length > 0){
        started = true;
      }
    }
    if(userData.hasOwnProperty('answers') && userData.answers.hasOwnProperty(question.drupal_id)){
      started = true;
      const answer = userData.answers[question.drupal_id];
      if(!answer.attributes.field_is_complete){
        completed = false;
        break;
      }
      if(answer.relationships.field_answer_option &&
        answer.relationships.field_answer_option.data){
        const answeredOptions = answer.relationships.field_answer_option.data;

        for(let option of answeredOptions){
          const answeredOptionNode = question.relationships.field_options.filter((op) => {
            return op.drupal_id === option.id
          })[0];
          if(!answeredOptionNode || option.id === 'missing'){
            completed = false;
          }
        }
      }
    }
    else if(userData.hasOwnProperty('answers') && !userData.answers.hasOwnProperty(question.drupal_id)){
      // we dont have an answer for every question and thus cannot be considered complete. this can happen if new
      // questions are added to the section
      completed = false;
    }


  }
  if(!started){
    return 'not started';
  }
  else if(!completed){
    return 'in progress'
  }
  else{
    return 'completed';
  }
}

export function minHeightEffect(minHeightState){
  const [minHeight, setMinHeight] = minHeightState;
  return () => {
    const curHeight = document.getElementsByClassName('questions').item(0).clientHeight;
    if(curHeight > minHeight){
      setMinHeight(curHeight);
    }
  }
}
