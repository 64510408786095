import React, { useEffect } from "react";

import { checkIfDuplicateModification } from "../services/auth";
import Auth from "../services/auth";

import Layout from "../components/layout/layout.js";
import LoginForm from "../components/form/LoginForm.js";

import "argon-dashboard-react/src/assets/scss/argon-dashboard-react.scss";
import "../components/navbar/navbar.scss";
import {mergeDeep} from "../utils/replacements";

import * as idbKeyval from  "idb-keyval";
import ImagineSpinner from "../components/spinner";

const auth = new Auth();
export const UserContext = React.createContext();
const initialState = {
  auth: auth,
  userData: {},
  loading: false,
  initialLoadingComplete: false,
  requests: 0,
};

function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'startRequest':
      return {
        ...state,
        requests: state.requests + 1,
      };
    case 'endRequest':
      return {
        ...state,
        requests: state.requests - 1,
      };
    case 'loginUser':
      return {
        ...state,
        isAuthenticated: true,
      };
    case 'logoutUser':
      return {
        ...state,
        userData: {},
        isAuthenticated: false,
        initialLoadingComplete: false
      };
    case 'beginLoading':
      return {
        ...state,
        loading: true
      };
    case 'completeLoading':
      return {
        ...state,
        loading: false,
        initialLoadingComplete: true
      };
    case 'updateUserData':
      return {
        ...state,
        userData: {
          ...state.userData,
          ...action.payload.userData
        },
      };
    case 'updateBackendUserData':
      if(state.requests === 0) {
        return {
          ...state,
          userData: {
            ...state.userData,
            ...action.payload.userData
          },
        };
      }
      else{
        return state;
      }
    case 'invalidateUserData':
      return {
        ...state,
        userData: {}
      };
    default:
      return state;
  }
};

/**
 * A listener for the form submit of the login form. The first two arguments are bound by UserContextProvider.
 * @param {Auth} auth
 * @param dispatch
 * @param ev
 * @returns {Promise<void>}
 */
async function handleLogin(auth, dispatch, ev){
  ev.preventDefault();
  const token = await auth.loginUser(ev);
  if(token) {
    dispatch({
      type: 'loginUser',
      payload: {
        auth: auth
      }
    });
  }
  else{
    //TODO: inform the user that they failed to log in.
    return false;
  }
}

async function handleLogout(auth, dispatch, ev){
  ev.preventDefault();
  const loggedOut = await auth.logoutUser(ev);
  //updates the state which forces a re render which will force the user off the page
  if(loggedOut){
    dispatch({
      type: 'logoutUser',
      payload: {
        auth: auth
      }
    });
    window.location.reload(false);
  }
  return loggedOut;
}

/**
 * Traverses the data object, searching for the entity of type `type` which contains the data
 * indicated by locationArr and matches the identifyingPropertyCondition. It picks the first one to match this condition, so
 * you probably want the parameters you give to map to only a single unique entity in your data.
 *
 * @param {Object} data
 * @param {array} locationArr An array of indexes which digs into the data object. Wherever it points to an array will
 * be iterated through. Sub-objects that dont have the key present specified by the identifyingPropertyString will be have their
 * values iterated through to find that key. For example, in the string 'answers.relationships.field_options.value', if
 * 'answers' is an object without the 'relationships' key, each of its entries will be checked for the 'relationships'
 * key. If 'field_options' is an array, each of its values will be checked for the 'value' key.
 * @param equalityCondition
 * If this parameter is a non-function, it does a simple `===` check between the potential value and this parameter.
 * If this parameter is a function, it passes the potential value into the function and expects a boolean to indicated equality.
 * @param {string | function} type The type of entity this locator is looking for. If a string, it compares type against it,
 * if a function, it passes the type into the function which should return true or false to match it.
 * @param returnValType Either "entity" to return the entity, or "value" for the matched data within the entity
 * @returns {Any | null} Returns an entity's data/matched data or null, indicating the entity does not exist yet.
 */
export function locateEntityInData(data, locationArr, equalityCondition, type=null, returnValType='entity'){
  if(!data){
    return null;
  }
  if(typeof equalityCondition !== 'function'){
    const tmpEqualityCondition = equalityCondition;
    equalityCondition = (dataItem) => dataItem === tmpEqualityCondition;
  }

  if(typeof type !== 'function' && type !== null){
    const tmpType = type;
    type = (dataItem) => dataItem === tmpType;
  }
  /**
   *
   * @param {Array} curLocationArr
   * @param curData
   * @param curEntity
   */
  let first = true;
  function* entityDataLocatorRecursive(curLocationArr, curData, curEntity = null){
    if(curData === null || typeof curData === 'undefined'){
      return false;
    }

    if(typeof curData === 'object' && !Array.isArray(curData) && curLocationArr.length > 0 &&
      !curData.hasOwnProperty(curLocationArr[0])){
      curData = Object.values(curData);
    }
    else if(curData.hasOwnProperty('type') && (
      (!type && first) ||  // if there is no specified type input, assume the first entity that has a type
      (typeof curData === 'object' && // otherwise, choose the nearest entity matching the specified type
      !Array.isArray(curData) &&
      (type !== null && type(curData.type))))){
      curEntity = curData;
      first = false;
    }

    if(Array.isArray(curData) && isNaN(Number(curLocationArr[0]))){
      for(let curDataItem of curData) {
        yield* entityDataLocatorRecursive(curLocationArr, curDataItem, curEntity)
      }
    }
    else if(curLocationArr.length > 0 &&  !curData.hasOwnProperty(curLocationArr[0])){
      yield false;
    }
    else if(curLocationArr.length === 0){
      first = true;
      yield [curData, curEntity];
    }
    else{
      yield* entityDataLocatorRecursive(curLocationArr.slice(1), curData[curLocationArr[0]], curEntity);
    }

  }

  for(let [potentialValue, potentialEntity] of entityDataLocatorRecursive(locationArr, data)){
    if(equalityCondition(potentialValue)){
      if(potentialEntity){
        if(returnValType === 'entity'){
          return potentialEntity;
        }
        else if(returnValType === 'value'){
          return potentialValue
        }
      }
    }
  }

  return null;
}

/**
 *
 * @param entityUserDataKey
 * @returns {EntityDataInserter}
 */
function useEntityInsertionBySingleEntry(entityUserDataKey){
  return (userData, entity, idOnly=false, del=false) => {
    if(!userData.hasOwnProperty(entityUserDataKey)){
      userData[entityUserDataKey] = {};
    }
    const hasNotDataChanged = compareDeepMerge(userData[entityUserDataKey], entity);
    userData[entityUserDataKey] = mergeDeep(userData[entityUserDataKey], entity);
    return hasNotDataChanged;
  }
}

/**
 *
 * @param {array} identifyingPropertyArr
 * @returns {EntityDataInserter}
 */
function useEntityInsertionByIdentifyingProperty(identifyingPropertyArr){
  return (userData, entity, idOnly=false, del=false) => {
    // since the entity is removed from the userData object, the identifying property arr should skip the first value
    const identifyingProp = locateEntityInData(entity, identifyingPropertyArr.slice(1), () => true, null, 'value');
    if (!identifyingProp) return false;
    if(!userData.hasOwnProperty(identifyingPropertyArr[0])){
      userData[identifyingPropertyArr[0]] = {};
    }

    if(!userData[identifyingPropertyArr[0]].hasOwnProperty(identifyingProp)){
      userData[identifyingPropertyArr[0]][identifyingProp] = {};
    }

    const dataNotChanged = compareDeepMerge(userData[identifyingPropertyArr[0]][identifyingProp], entity);
    if(idOnly){
      // if you ask for "id only" insertion, you need to have an id in your entity
      entity = { id: entity.id }
    }
    const mergedData = mergeDeep(userData[identifyingPropertyArr[0]][identifyingProp], entity);
    if(!dataNotChanged){
      if(!mergedData.attributes){
        mergedData.attributes = {};
      }
      mergedData.attributes.local_changed = Math.round(new Date().getTime() / 1000);
    }
    userData[identifyingPropertyArr[0]][identifyingProp] = mergedData;

    return dataNotChanged;
  }
}
/**
 *
 * @param entityUserDataKey
 * @returns {EntityDataInserter}
 */
export function useEntityInsertionById(entityUserDataKey){
  return (userData, entity, idOnly=false, del=false) => {
    if(!userData.hasOwnProperty(entityUserDataKey)){
      userData[entityUserDataKey] = {};
    }

    let entityId = entity.id ? entity.id : entity.local_id;

    if(del){
      if(userData[entityUserDataKey][entityId]){
        delete userData[entityUserDataKey][entityId];
        return false;
      }
      return true;
    }

    // we want to prevent storing the local_id as the real id of the entity,
    // but the event handlers that call `handle` will not know the difference.
    // they may pass in the local_id as the id, and we should remove the id in that case
    if(entity.id && entity.id === entity.local_id){
      delete entity.id;
    }
    // if we have a real id, we want to stop storing its entity at the location of the local id, so we can move it into
    // its permanent home under the id given by the backend
    else if(entity.id && entity.local_id && userData[entityUserDataKey][entity.local_id]){
      userData[entityUserDataKey][entity.id] = userData[entityUserDataKey][entity.local_id];
      delete userData[entityUserDataKey][entity.local_id];
    }
    if(!userData[entityUserDataKey].hasOwnProperty(entityId)){
      userData[entityUserDataKey][entityId] = {};
    }

    const dataNotChanged = compareDeepMerge(userData[entityUserDataKey][entityId], entity);
    if(idOnly){
      entity = entity.local_id ? { id: entity.id, local_id: entity.local_id } : { id: entity.id }
    }
    const mergedData = mergeDeep(userData[entityUserDataKey][entityId], entity);
    if(!dataNotChanged){
      if(!mergedData.attributes){
        mergedData.attributes = {};
      }
      mergedData.attributes.local_changed = Math.round(new Date().getTime() / 1000);
    }
    userData[entityUserDataKey][entityId] = mergedData;
    return dataNotChanged;
  }
}
export function compareDeepMerge(target, source, parentKey=null){
  for(let sourceKey of Object.keys(source)){
    if(typeof source[sourceKey] === 'object'
      && typeof target[sourceKey] === 'object'
      && source[sourceKey] !== null
      && target[sourceKey] !== null){

      if(parentKey === 'relationships'){
        // relationships work a little differently. we don't want to track if the attributes of related entities changed
        // to decide if we want to update this entity. we only care if the relationship *itself* changed, i.e. if this
        // entity is now pointing at different related entities than it was before. we do this by comparing the ids of
        // the related entities between source and target
        if(!source[sourceKey].data || !target[sourceKey].data){
          throw Error('Missing a data property in a relationship field');
        }
        if(Array.isArray(source[sourceKey].data) !== Array.isArray(target[sourceKey].data)){
          // this is probably a developer error somewhere. a mistaken relationship field entered into a handle() body maybe
          throw Error('Mismatched schemas, multiple related entities applied to single entity relationship.');
        }
        if(Array.isArray(source[sourceKey].data)){
          // we are in a multiple value relationship field. the ids in the target and source need to be in one to one
          // correspondence in order to consider this field unchanged.
          const relIds = new Set();
          for(let rel of source[sourceKey].data){
            if(!rel.id){
              //related entities that do not have an id yet are ignored, as they will not be included in a backend request.
              continue;
            }
            relIds.add(rel.id);
          }
          for(let rel of target[sourceKey].data){
            if(!rel.id){
              continue;
            }
            if(!relIds.has(rel.id)){
              // a new entity has been added to the field
              return false;
            }
            relIds.delete(rel.id);
          }
          if(relIds.size > 0){
            // an entity has been removed from the field
            return false;
          }
        }
        else if(!source[sourceKey].data.id || !target[sourceKey].data.id ||
          source[sourceKey].data.id === target[sourceKey].data.id){
          continue;
        }
        else{
          return false;
        }
      }

      if(Array.isArray(target[sourceKey])
        && Array.isArray(source[sourceKey])
        && target[sourceKey].length !== source[sourceKey].length){
        // if the target and source are arrays of diff length, there has been a change.
        return false;
      }
      if(!compareDeepMerge(target[sourceKey], source[sourceKey], sourceKey)){
        return false;
      }
    }
    else if(!target.hasOwnProperty(sourceKey) || target[sourceKey] !== source[sourceKey]){
      return false;
    }
  }
  return true;
}

/**
 *
 * `handleQueue` is where requests are actually made, and should only have one instance awaiting at a time for a
 * given queue key.
 * TODO: add a lock around the queue key using navigator.locks (or a polyfill for browsers with missing implementations)
 *  this will prevent the queue cleanup from snatching the queue when another tab is still handling it
 * All other requests made with that same queue key should be deposited in a localStorage queue.
 * The queue keys are comprised of the identifying property array and value as formatted below
 * @param fetchAuthenticatedContent
 * @param {array} requestQueue
 * @param {string} requestQueueKey
 * @param {object} userData
 * @param dispatch
 * @returns {Promise<object | bool>}
 */
async function handleQueue(fetchAuthenticatedContent, requestQueue, requestQueueKey, userData, dispatch){

  let id = "";
  let fetchedEntityObj;
  let localId = false;
  while(requestQueue.length > 0){

    let req = requestQueue.shift();
    let {uri, method, body} = req;
    if(!localId && body && body.data && body.data.local_id){
      localId = body.data.local_id;
    }
    if(body && body.data && body.data.hasOwnProperty('id')){
      id = body.data.id;
    }
    // if we have an id, it should be in the body of the request
    // except when the request is not directly to the entity but to some field (i.e. something comes after the id)
    else if(id !== "" && !req.hasOwnProperty('uriAfterId')){
      if(!body.data){
        body.data = {};
      }
      body.data.id = id;
    }
    uri = req.hasOwnProperty('uriAfterId') ? `${uri}/${id}/${req.uriAfterId}` : `${uri}/${id}`;
    localStorage.setItem(requestQueueKey, JSON.stringify(requestQueue));
    let response;
    if(req.fileKey){
      const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
      /** @var File **/
      const file = await idbKeyval.get(req.fileKey);
      if(!file){
        continue;
      }
      const encodedFile = await toBase64(file);
      response = await fetchAuthenticatedContent(uri, method, file, {
        'Content-Type': 'application/octet-stream',
        'Content-Disposition': `file; filename="${file.name}"`
      });

      await idbKeyval.del(req.fileKey);
    }
    else if(method === 'DELETE'){
      response = await fetchAuthenticatedContent(uri, method);
    }
    else{
      let reqBody = JSON.parse(JSON.stringify(body));
      if (reqBody.data &&
          reqBody.data.attributes && 
          reqBody.data.attributes.local_changed) {
        delete reqBody.data.attributes.local_changed;
      }
      response = await fetchAuthenticatedContent(uri, method, reqBody);
    }
    if(checkIfDuplicateModification(response)){
      // There is another active edit made to this entity, causing a 422 error. This might happen if the queue cleanup
      // process runs while a queue is still being handled and not abandoned. One of the two async calls to
      // `handleQueue` could fail, but we only need one to succeed, so we can ignore this one.
      // one thing though:
      // if the edit is a new entity POST, we should not end up here by definition, since the POST is the thing that
      // creates the entity in the first place, so it can't be blocked out by an in-progress entity update.
      // the caller should be aware that, unless their initial request is a new entity POST, `handleQueue` can return
      // false
      return (typeof fetchedEntityObj === 'undefined' ? false : fetchedEntityObj);
    }
    if(method === 'DELETE'){
      break;
    }
    if(req.hasOwnProperty('insertEntityIntoUserData') && response.hasOwnProperty('data')){
      fetchedEntityObj = response;
      // put just the ID back into the userData, because we need it to know that an entity has been created
      if(localId){
        response.data.local_id = localId;
      }
      const hasNotChanged = req.insertEntityIntoUserData(userData, response.data, true);
      if(!hasNotChanged){
        localStorage.setItem("imagine_user_data", JSON.stringify(userData));
        dispatch({
          type: 'updateUserData',
          payload: {
            userData: userData
          }
        });
      }
      else{
       // req.insertEntityIntoUserData(userData, response)
      }
    }
    if(response.hasOwnProperty('data') && response.data.hasOwnProperty('id')){
      id = response.data.id
    }
    requestQueue = JSON.parse(localStorage.getItem(requestQueueKey));
    if(requestQueue === null){
      // Another call to `handleQueue` with this queue key has removed this queue. This can happen in the same situation
      // as the above duplicate modification error with the queue cleanup, but in this case the simultaneous queue
      // handling did not trigger a backend 422 error and thus did not exit early before removing the queue.
      return fetchedEntityObj;
    }

  }

  // at the end, remove the queue from storage to signal that all items have been processed
  localStorage.removeItem(requestQueueKey);
  return fetchedEntityObj;
}


export class EntityUpdateHandler{
  constructor({fetchAuthenticatedContent, dispatch, userData}){
    this.userData = userData;
    this.fetchAuthenticatedContent = fetchAuthenticatedContent;
    this.dispatch = dispatch;
    this.entityBody = null;
    this.identifyingPropertyString = null;
    this.identifyingPropertyCondition = null;
    this.relationships = [];
    this.newIdentifyingValue = null;
    this.updateBackendTimeout = null;
    this.localId = null;
    this.bindEntity = this.bindEntity.bind(this);
    this.addRelationship = this.addRelationship.bind(this);
    this.handle = this.handle.bind(this);
    this.getLocalId = this.getLocalId.bind(this);
  }

  /**
   *
   * Bind an entity to this handler, along with a way of finding if it exists yet
   * @param {Object} body The body of a new entity, to be POSTed to a JSONAPI endpoint
   * @param {string} body.type The type of the entity, as specified by the JSONAPI
   *  (should be in the format `entityType--bundle`)
   * @param {string} identifyingPropertyString A string that points to a property in the
   * @param {function} identifyingPropertyCondition A function for determining if the value of the property specified by
   * the `identifyingPropertyString` in the **`userData` state** matches the entity we want to bind.
   * @param insertIntoUserData
   * @param newIdentifyingValue A value to set the property specified by the `identifyingPropertyString` in the
   * **request body**. This is likely to be given at the point in which `handle()` is called and we know what this value
   * should be set to. A shortcut to modifying the request body yourself.
   * @param {object} file
   */
  bindEntity({body, identifyingPropertyString, identifyingPropertyCondition, insertIntoUserData, newIdentifyingValue, updateBackendTimeout, updateBackendTimeoutLength, files}){
    if(body) {
      this.entityBody = body;
    }
    if(identifyingPropertyString){
      this.identifyingPropertyString = identifyingPropertyString;
    }

    if(identifyingPropertyCondition){
      this.identifyingPropertyCondition = identifyingPropertyCondition;
    }
    if(insertIntoUserData){
      this.insertEntityIntoUserData = insertIntoUserData;
    }
    if(newIdentifyingValue){
      this.newIdentifyingValue = newIdentifyingValue;
    }
    if(updateBackendTimeout){
      this.updateBackendTimeout = updateBackendTimeout;
    }
    if(updateBackendTimeoutLength){
      this.updateBackendTimeoutLength = updateBackendTimeoutLength;
    }
    if(files){
      this.files = files;
    }
  }

  getLocalId(){
    const localId = `local-${uuidv4()}`;
    this.localId = localId;
    return localId;
  }
  /**
   *
   * @param {string} relationshipEntityType
   * @param {string} relationshipField
   * @param {string} relatedEntityIdentifyingPropertyString
   * @param relatedEntityIdentifyingPropertyCondition
   * @param relatedEntityFieldCardinality
   */
  addRelationship(relationshipEntityType, relationshipField,
                  relatedEntityIdentifyingPropertyString, relatedEntityIdentifyingPropertyCondition, relatedEntityFieldCardinality = 'multiple'){
    this.relationships.push({
      field: relationshipField,
      type: relationshipEntityType,
      identifyingPropertyString: relatedEntityIdentifyingPropertyString,
      identifyingPropertyCondition: relatedEntityIdentifyingPropertyCondition,
      // TODO: infer this information based on field information from Drupal (possibly through JSONAPI schema, an upcoming feature)
      cardinality: relatedEntityFieldCardinality
    });
  }

  getEntity(){
    const entityType = this.entityBody.data.type,
      identifyingPropertyCondition = this.identifyingPropertyCondition,
      identifyingPropertyArr = this.identifyingPropertyString.split("."),
      userData = this.userData;
    return locateEntityInData(userData, identifyingPropertyArr, identifyingPropertyCondition, entityType);
  }

  getEntityIdentifyingValue(){
    const entityType = this.entityBody.data.type,
      identifyingPropertyCondition = this.identifyingPropertyCondition,
      identifyingPropertyArr = this.identifyingPropertyString.split("."),
      userData = this.userData;
    return locateEntityInData(userData, identifyingPropertyArr, identifyingPropertyCondition, entityType, 'value');
  }

  /**
   *
   * @param {object} entityArgs An object passed to `bindEntity` in order to initialize the arguments that identify an entity
   * @param {bool} del If true, the identified entity will be deleted, if it exists.
   * @returns {Promise<Any>}
   */
  async handle(entityArgs = {}, del = false) {
    this.bindEntity(entityArgs);
    if (!this.entityBody || !this.entityBody.hasOwnProperty('data')) {
      throw Error('No entity bound to this handler yet.');
    }
    if (!this.entityBody.data.hasOwnProperty('type')) {
      throw Error('You must set a type in your POST body');
    }
    let entityBody = this.entityBody;

    let identifyingPropertyString;

    function inferTypeInfo(splittableType) {
      const splitType = splittableType.split('--');
      if (splitType.length !== 2) {
        throw Error('Entity type not formatted as "entity_type--bundle_type". Could not infer type information');
      }
      return splitType;
    }

    function getBundleType(splittableType) {
      return inferTypeInfo(splittableType)[1];
    }

    if (!this.identifyingPropertyString) {
      identifyingPropertyString = getBundleType(entityBody.data.type);
    }
    else {
      identifyingPropertyString = this.identifyingPropertyString;
    }

    const identifyingPropertyArr = identifyingPropertyString.split("."),
      fetchAuthenticatedContent = this.fetchAuthenticatedContent,
      dispatch = this.dispatch,
      userData = this.userData,
      entityType = this.entityBody.data.type,
      newIdentifyingValue = this.newIdentifyingValue;

    let insertEntityIntoUserData = this.insertEntityIntoUserData ? this.insertEntityIntoUserData : useEntityInsertionByIdentifyingProperty(identifyingPropertyArr);
    let identifyingPropertyCondition;
    if (!this.identifyingPropertyCondition) {
      identifyingPropertyCondition = () => true;
      if (!this.insertEntityIntoUserData) {
        insertEntityIntoUserData = useEntityInsertionBySingleEntry(identifyingPropertyArr[0]);
      }
    }
    else {
      identifyingPropertyCondition = this.identifyingPropertyCondition;
    }


    // if we have an identifying value, we should set that property in our entity body,
    // if it's not already set.
    if (newIdentifyingValue !== null) {
      let curBody = entityBody.data;
      const idProp = identifyingPropertyArr.length - 1;
      // assume for now that the entity's properties begin after the first prop,
      // fill in any missing objects before setting the identifying value
      for (let prop of identifyingPropertyArr.slice(1, idProp)) {
        if (!curBody.hasOwnProperty(prop)) {
          curBody[prop] = {};
        }
        curBody = curBody[prop];
      }
      if (!curBody.hasOwnProperty(identifyingPropertyArr[idProp])) {
        curBody[identifyingPropertyArr[idProp]] = newIdentifyingValue;
      }


    }

    let entity = locateEntityInData(userData, identifyingPropertyArr, identifyingPropertyCondition, entityType);
    let fetchedEntityObj;
    if(entityBody.data && this.localId){
      entityBody.data.local_id = this.localId;
    }
    // insert the initial version, prior to receiving a response
    const entityHasNotChanged = insertEntityIntoUserData(userData, entityBody.data, false, del);
    if (entityHasNotChanged && !this.files) {
      return entity;
    }
    await localStorage.setItem("imagine_user_data", JSON.stringify(userData));
    dispatch({
      type: 'updateUserData',
      payload: {
        userData: userData
      }
    });
    /**
     *
     * @param {string} entityType
     */
    function convertToEntityUri(entityType){
      let [type, bundle] = inferTypeInfo(entityType);
      return `${type}/${bundle}`
    }

    /**
     * This is the function that runs when we actually want to change the back end. This is not necessarily on every
     * call to `handle`, we might
     * @returns {Promise<void>}
     */
    async function updateBackend(){
      // clone the entityBody so as not to affect the userData, as they could diverge for the purposes of making backend
      // requests
      entityBody = JSON.parse(JSON.stringify(entityBody));
      // clear out any related entities without ids
      if(entityBody.data.relationships){
        for(let [field, rel] of Object.entries(entityBody.data.relationships)){
          if(rel.data){
            if(Array.isArray(rel.data)){
              for(let relEnt of rel.data){
                if(!relEnt.id){
                  delete entityBody.data.relationships[field];
                  break;
                }
              }
            }
            else if(!rel.data.id){
              delete entityBody.data.relationships[field];
            }
          }
          else{
            delete entityBody.data.relationships[field];
          }
        }
      }
      // TODO: functional identifying property conditions dont turn into hashable values for a key... might have to deprecate them
      const requestQueueKey = typeof identifyingPropertyCondition === 'string' ?
        `request:${identifyingPropertyString}:${identifyingPropertyCondition}` : `request:${identifyingPropertyString}`;

      const entityUri = this.entityUri ? this.entityUri : convertToEntityUri(entityType);
      let existingRequestQueue = localStorage.getItem(requestQueueKey);


      function appendFileUploadRequests(files, requestQueue, id=null){
        if(files){
          const dummyBody = id ? { data: { id: id } } : {};
          for(let [field, file] of Object.entries(files)){
            const fileKey = uuidv4();
            idbKeyval.set(fileKey, file);
            requestQueue.push({
              uri: entityUri,
              method: 'POST',
              fileKey: fileKey,
              uriAfterId: field,
              body: dummyBody,
            })
          }
        }
        return requestQueue;
      }
      // if del is true, we have been expressly told to DELETE this entity.
      if(del){
        if(entity && entity.id){
          entityBody.data.id = entity.id;
        }
        const requestObj = {
          body: entityBody,
          method: 'DELETE',
          uri: entityUri,
          insertEntityIntoUserData: insertEntityIntoUserData,
        };

        if(existingRequestQueue){
          /** @var {array} **/
          existingRequestQueue = JSON.parse(existingRequestQueue);
          if(existingRequestQueue.length === 0){
            // if an empty queue exists, it means a request for our entity has been made, but no next request has been
            // queued. so we push the next request onto the queue.
            existingRequestQueue.push(requestObj);
          }
          else{
            // if there is something in our queue already, that will be the next request and we should apply any changes
            // to it.
            if(compareDeepMerge(existingRequestQueue[0].body, entityBody)){
              requestObj.body = mergeDeep(existingRequestQueue[0].body, entityBody);
            }
            existingRequestQueue = [requestObj];
          }
          localStorage.setItem(requestQueueKey, JSON.stringify(existingRequestQueue));

          // we return early because there is already a request out for this entity, so the handler for the original
          // request will be in charge of initiating the next request in the queue.
          return;
        }
        // If we make it here, there haven't been any requests queued so we can safely send ours.
        // Initialize our queue with our first request, which will be immediately processed
        let requestQueue = [requestObj];
        fetchedEntityObj = await handleQueue(fetchAuthenticatedContent, requestQueue, requestQueueKey, userData, dispatch);
      }
      // check if an entity with an id exists to know if we should POST or PATCH our change. if so, or if there is
      // already another request prior to this one, we know that we shouldn't re-POST this entity.
      else if(!existingRequestQueue &&
          (!entity || !entity.hasOwnProperty('id')) ){
        let requestQueue = [{
          uri: entityUri,
          method: 'POST',
          body: entityBody,
          insertEntityIntoUserData: insertEntityIntoUserData
        }];

        appendFileUploadRequests(this.files, requestQueue);

        // if the entity doesn't exist, POST a new entity to the general entity endpoint
        fetchedEntityObj = await handleQueue(fetchAuthenticatedContent, requestQueue, requestQueueKey, userData, dispatch);
        const fetchedEntityId = fetchedEntityObj.data.id;
        // since this is the first time around, add in any references that need to exist to the new entity
        const newRelationship = {
          type: entityType,
          id: fetchedEntityId
        };

        // set any specified references to the bound entity that need to exist on some other entity
        // TODO: should be done as a transaction, see operations in the jsonapi spec
        for (let relationship of this.relationships){
          if (!relationship.identifyingPropertyString && !relationship.type){
            throw Error('Cannot locate related entity without location information.')
          }
          let relatedIdentifyingPropertyString = relationship.identifyingPropertyString ?
            relationship.identifyingPropertyString : getBundleType(relationship.type);

          let relatedIdentifyingPropertyCondition = relationship.identifyingPropertyCondition ?
            relationship.identifyingPropertyCondition : () => true;

          let relatedEntity = locateEntityInData(userData, relatedIdentifyingPropertyString.split('.'), relatedIdentifyingPropertyCondition, relationship.type);
          let relatedEntityUri = convertToEntityUri(relationship.type);

          if (relationship.cardinality === 'multiple'){
            let relatedRequestQueueKey = typeof relatedIdentifyingPropertyCondition === 'string' ?
              `request:${relatedIdentifyingPropertyString}:${relatedIdentifyingPropertyCondition}` :
              `request:${relatedIdentifyingPropertyString}`;
            let existingRelatedRequestQueue = localStorage.getItem(relatedRequestQueueKey);
            let relationshipRequestBody = {
              data: [
                newRelationship
              ]
            };

            // Add the new relationship to this
            let modifyRelatedEntity = (userData, relationshipSuccess) =>{
              let relatedEntity = locateEntityInData(userData, relatedIdentifyingPropertyString.split('.'), relatedIdentifyingPropertyCondition, relationship.type);
              if(relationshipSuccess){
                if(!relatedEntity.hasOwnProperty('relationships')){
                  relatedEntity.relationships = {};
                }
                if(!relatedEntity.relationships.hasOwnProperty(relationship.field)){
                  relatedEntity.relationships[relationship.field] = {};
                }
                if(!relatedEntity.relationships[relationship.field].hasOwnProperty('data')){
                  relatedEntity.relationships[relationship.field].data = [];
                }
                relatedEntity.relationships[relationship.field].data.push(newRelationship);
              }
            };


            let relatedRequestObj = {
              body: relationshipRequestBody,
              method: 'POST',
              insertEntityIntoUserData: modifyRelatedEntity
            };
            if(relatedEntity && relatedEntity.hasOwnProperty('id')){
              relatedRequestObj.uri = `${relatedEntityUri}/${relatedEntity.id}/relationships/${relationship.field}`
            }
            else{
              relatedRequestObj.uri =  relatedEntityUri;
              relatedRequestObj.uriAfterId = `relationships/${relationship.field}`;
            }

            if(existingRelatedRequestQueue){
              existingRelatedRequestQueue = JSON.parse(existingRelatedRequestQueue);
              //should be an array
              existingRelatedRequestQueue.push(relatedRequestObj);
              localStorage.setItem(requestQueueKey, JSON.stringify(existingRelatedRequestQueue));
              continue;
            }

            await handleQueue(fetchAuthenticatedContent, [relatedRequestObj], relatedRequestQueueKey, userData, dispatch);
          }
          else if (relationship.cardinality === 'single'){
            //TODO: figure out how to do this in the case of a single cardinality field
          }
          else{
            throw Error("Unrecognized cardinality for entity reference field (should be 'single' or 'multiple').")
          }


        }
      }
      else{
        if(entity && entity.id){
          entityBody.data.id = entity.id;
        }
        const requestObj = {
          body: entityBody,
          method: 'PATCH',
          uri: entityUri,
          insertEntityIntoUserData: insertEntityIntoUserData,
        };

        if(existingRequestQueue){
          /** @var {array} **/
          existingRequestQueue = JSON.parse(existingRequestQueue);
          if(entityHasNotChanged){

          }
          if(existingRequestQueue.length === 0){
            // if an empty queue exists, it means a request for our entity has been made, but no next request has been
            // queued. so we push the next request onto the queue.
            existingRequestQueue.push(requestObj);
          }
          else{
            // if there is something in our queue already that isnt a file upload, that will be the next request and we should apply any changes
            // to it.
            for(let req of existingRequestQueue){
              if(!req.fileKey && compareDeepMerge(req.body, entityBody)){
                req.body = mergeDeep(req.body, entityBody);
                break;
              }
            }
          }
          appendFileUploadRequests(this.files, existingRequestQueue, entityBody.data.id);
          if(!entityHasNotChanged || this.files){
            localStorage.setItem(requestQueueKey, JSON.stringify(existingRequestQueue));
          }

          // we return early because there is already a request out for this entity, so the handler for the original
          // request will be in charge of initiating the next request in the queue.
          return;
        }
        // If we make it here, there haven't been any requests queued so we can safely send ours.
        // Initialize our queue with our first request, which will be immediately processed
        //
        let requestQueue = entityHasNotChanged ? [] : [requestObj];
        appendFileUploadRequests(this.files, requestQueue, entityBody.data.id);
        fetchedEntityObj = await handleQueue(fetchAuthenticatedContent, requestQueue, requestQueueKey, userData, dispatch);
      }

      if(!existingRequestQueue){
        // insertEntityIntoUserData(userData, fetchedEntityObj.data);
      }
      dispatch({
        type: 'endRequest'
      });

      // localStorage.setItem("imagine_user_data", JSON.stringify(userData));

      // this update will only affect the user data state if all requests have been completed (i.e. the `requests`
      // counter is at 0) TODO: currently not working, so commented out until fixed
      // dispatch({
      //   type: 'updateBackendUserData',
      //   payload: {
      //     userData: userData
      //   }
      // });
      // reset the timeout so that the request counter increments next time
      if(this.updateBackendTimeout){
        const [_, timeoutSetter] = this.updateBackendTimeout;
        timeoutSetter(null);
      }
    }

    if(this.updateBackendTimeout){
      const [timeoutId, timeoutSetter] = this.updateBackendTimeout;
      if(!timeoutId){
        // signal that we've started processing an update backend request, incrementing the counter
        dispatch({
          type: 'startRequest',
        });
      }
      clearTimeout(timeoutId);
      let updateBackendTimeoutLength = 0;
      if(this.updateBackendTimeoutLength){
        updateBackendTimeoutLength = this.updateBackendTimeoutLength;
      }
      const newTimeoutId = setTimeout(updateBackend.bind(this), updateBackendTimeoutLength);
      timeoutSetter(newTimeoutId);
    }
    else{
      await updateBackend.call(this);
    }
    return entity;
  }

  async delete(entityArgs = {}){
    await this.handle(entityArgs, true);
  }
}

function getEntityUpdateHandler(user){
  return new EntityUpdateHandler(user);
}

/*
  Not sure where to credit this, some guy John Long
  introduced this as a solution to parsing urls, I found
  it on github.
*/
function parseURL(url) {
    var parser = document.createElement('a'),
        searchObject = {},
        queries, split, i;
    // Let the browser do the work
    parser.href = url;
    // Convert query string to object
    queries = parser.search.replace(/^\?/, '').split('&');
    for( i = 0; i < queries.length; i++ ) {
        split = queries[i].split('=');
        searchObject[split[0]] = split[1];
    }
    return {
        protocol: parser.protocol,
        host: parser.host,
        hostname: parser.hostname,
        port: parser.port,
        pathname: parser.pathname,
        search: parser.search,
        searchObject: searchObject,
        hash: parser.hash
    };
}

/*
  part of the hotfix solution. Because jsonapi limits results to 50, we could make some core config changes
  or something that would change that limit I from what I read, but this seems like the canonical solution.
  We also could probably look into a more lazy load approach, instead of loading 100s if not 1000s of data
  entries we load what we need as we need it. But there are pros and cons to both.
  But essentially we follow the next links which give us the page offset and limit to give us the next set of
  data until we have all of the data. If we only gathered the first 50 answers given, then there would likely be
  missing answers, and incorrect answers.
  This data is then passed back to the loadUserData method and inside there we find the one with the most recent
  changed date.
*/
async function fetchAll(fetchMethod, endpoint){
  let allData = [];
  let allIncluded = [];
  let request = await fetchMethod(endpoint);
  allData = allData.concat(request.data);
  if(request.included){
    allIncluded = allIncluded.concat(request.included);
  }
  if(request.links){
    while(request.links.next){
      const nextLink = parseURL(request.links.next.href);
      //assumes jsonapi will be the first section of pathname. When you split you get ["", "jsonapi", "node", ...]
      //this has to be really unique, because we need to remove everything before the jsonapi. On staging the pathname is /drupal/jsonapi/...
      //locally its just /jsonapi/...
      const pathName = nextLink.pathname.split('/')
      const indexToSlice = pathName.indexOf('jsonapi')
      const nextEndpoint = pathName.slice(indexToSlice+1).join('/') + nextLink.search;
      request = await fetchMethod(nextEndpoint);
      allData = allData.concat(request.data);
      if(request.included){
        allIncluded = allIncluded.concat(request.included);
      }
    }
  }
  return {
    data: allData,
    included: allIncluded
  };
}

async function authSubrequestLoader(auth, dispatch, loadById=""){

  const filterUserId = loadById ? loadById : '{{userId.body@$.meta.links.me.meta.id}}';

  const userIdRequest = {
    requestId: 'userId',
    uri: '/jsonapi',
  }

  const sectionStatusRequest = {
    requestId: 'sectionStatus',
    waitFor: 'userId',
    uri: '/jsonapi/node/section_status',
    options: {
      filter: {
        'uid.id': filterUserId
      },
    }
  }

  const conversationRequest = {
    requestId: 'conversation',
    uri: '/jsonapi/node/conversation',
    options: {
      include: ['uid', 'field_user_permission'],
      sort: ['created']
    }
  }

  const messageRequest = {
    requestId: 'message',
    uri: '/jsonapi/node/message',
    options: {
      include: ['uid', 'field_user_permission'],
      sort: ['created']
    }
  }

  const selectAnswerRequest = {
    requestId: 'selectAnswer',
    waitFor: 'userId',
    uri: '/jsonapi/node/select_answer',
    options: {
      include: ['field_question_reference'],
      fields: {
        'node--checkboxes_question': ['id',],
        'node--radios_question': ['id',],
      },
      filter: {
        'uid.id': filterUserId
      },
    }
  }

  const textAnswerRequest = {
    requestId: 'textAnswer',
    waitFor: 'userId',
    uri: '/jsonapi/node/text_answer',
    options: {
      include: ['field_question_reference'],
      fields: {
        'node--text_question': ['id',],
      },
      filter: {
        'uid.id': filterUserId
      },
    }
  }

  const scheduleAnswerRequest = {
    requestId: 'scheduleAnswer',
    waitFor: 'userId',
    uri: '/jsonapi/node/schedule_answer',
    options: {
      include: ['field_question_reference'],
      filter: {
        'uid.id': filterUserId
      },
    }
  }

  const imageAnswerRequest = {
    requestId: 'imageAnswer',
    waitFor: 'userId',
    uri: '/jsonapi/node/image_answer',
    options: {
      include: ['field_question_reference', 'field_answer_image'],
      filter: {
        'uid.id': filterUserId
      },
    }
  }

  const eventAnswerRequest = {
    requestId: 'eventAnswer',
    waitFor: 'userId',
    uri: '/jsonapi/node/event_answer',
    options: {
      filter: {
        'uid.id': filterUserId
      }
    }
  }

  const userProfileRequest = {
    requestId: 'userProfile',
    waitFor: 'userId',
    uri: '/jsonapi/user/user',
    options: {
      include: ['roles', 'user_picture'],
      filter: {
        id: '{{userId.body@$.meta.links.me.meta.id}}'
      },
    }
  }

  const requests = {};
  requests[sectionStatusRequest.requestId]   = sectionStatusRequest;
  requests[conversationRequest.requestId]    = conversationRequest;
  requests[messageRequest.requestId]         = messageRequest;
  requests[userIdRequest.requestId]          = userIdRequest;
  requests[selectAnswerRequest.requestId]    = selectAnswerRequest;
  requests[textAnswerRequest.requestId]      = textAnswerRequest;
  requests[scheduleAnswerRequest.requestId]  = scheduleAnswerRequest;
  requests[imageAnswerRequest.requestId]     = imageAnswerRequest;
  requests[eventAnswerRequest.requestId]     = eventAnswerRequest;
  requests[userProfileRequest.requestId]     = userProfileRequest;

  const answerData = {};

  const initResponses = await auth.authSubrequest(Object.values(requests));


  //a magic number that drupal 8's jsonapi sets. This is the max amount of data you
  //can fetch in a single jsonapi request. We use this as a multiplier when generating
  //our pagination offsets. This number can be lowered, but it can't be set higher than
  //jsonapis limit or whatever we set the limit to if we decide to try that.
  const JSON_API_LIMIT = 50;

  const paginatedRequests = {userId: requests.userId};
  Object.entries(initResponses).map( ([id, data]) => {
    id = id.split('#')[0];

    let included = [];
    let respData = [];
    if(data.hasOwnProperty('data')){
      respData = data.data;
    }
    if(data.hasOwnProperty('included')){
      included = data.included;
    }

    answerData[id] = {included: included, data: respData};

    if(data.hasOwnProperty('meta') && data.meta.hasOwnProperty('count')){
      const count = data.meta.count;
      const iterations = Math.ceil(count / JSON_API_LIMIT)
      const limit = JSON_API_LIMIT;

      //we start off with 1 because we want to skip the first set since we've already fetched it
      for(let i = 1; i < iterations; i++){
        const offset = i*JSON_API_LIMIT;
        const newRequest = JSON.parse(JSON.stringify(requests[id]));
        newRequest.requestId = newRequest.requestId + '#' + offset;
        newRequest.options.page = {limit: limit, offset: offset};
        paginatedRequests[newRequest.requestId] = newRequest;
      }
    }
  });

  const paginatedResponses = await auth.authSubrequest(Object.values(paginatedRequests));

  Object.entries(paginatedResponses).map( ([id, data]) => {
    id = id.split('#')[0];
    let included = [];
    let respData = [];
    if(data.hasOwnProperty('data')){
      respData = data.data;
    }
    if(data.hasOwnProperty('included')){
      included = data.included;
    }

    answerData[id].data = answerData[id].data.concat(respData)
    answerData[id].included = answerData[id].included.concat(included);

  });

  return answerData;

}



async function loadUserData(fetchUserData, dispatch, loadById=""){
  let userData = {};
  //Policy

  const rawUserData = await fetchUserData(loadById);


  const sectionStatus = rawUserData.sectionStatus;
  const conversationData = rawUserData.conversation;
  const messageData = rawUserData.message;
  const selectAnswerData = rawUserData.selectAnswer;
  const textAnswerData = rawUserData.textAnswer;
  const scheduleAnswerData = rawUserData.scheduleAnswer;
  const imageAnswerData = rawUserData.imageAnswer;
  const eventAnswerData = rawUserData.eventAnswer;
  const userProfile = rawUserData.userProfile;
  userData.profile = {};
  userData.profile[rawUserData.userProfile.data[0].id] = {data: rawUserData.userProfile.data[0], included: rawUserData.userProfile.included};

  userData.sectionStatus = {};
  for(let sectionLog of sectionStatus.data){
    userData.sectionStatus[sectionLog.relationships.field_section.data.id] = sectionLog;
  }

  userData.conversation = {};
  for(let conversation of conversationData.data){
    userData.conversation[conversation.id] = conversation;
  }

  userData.message = {};
  for(let message of messageData.data){
    userData.message[message.id] = message;
  }

  userData.user = {};
  for(let conversationInclude of conversationData.included){
    switch(conversationInclude.type){
      case "user--user":
        userData.user[conversationInclude.id] = conversationInclude;
        break;
    }
  }

  for(let messageInclude of messageData.included){
    switch(messageInclude.type){
      case "user--user":
        userData.user[messageInclude.id] = messageInclude;
        break;
    }
  }

  const imageAnswerIncludedQuestions = {};
  for(let imageIncluded of imageAnswerData.included){
    if(imageIncluded.type === 'file--file'){
      for(let imageAnswer of imageAnswerData.data){
        if(imageAnswer.relationships &&
          imageAnswer.relationships.field_answer_image &&
          imageAnswer.relationships.field_answer_image.data &&
          imageAnswer.relationships.field_answer_image.data.id === imageIncluded.id ){
          imageAnswer.relationships.field_answer_image.data = {
            ...imageAnswer.relationships.field_answer_image.data,
            ...imageIncluded
          };
          break;
        }
      }
    }
    else if(imageIncluded.type === 'node--image_question'){
      imageAnswerIncludedQuestions[imageIncluded.id] = imageIncluded;
    }
  }
  const answerData = selectAnswerData.data.concat(textAnswerData.data).concat(scheduleAnswerData.data).concat(imageAnswerData.data);
  const includedQuestions = selectAnswerData.included.concat(textAnswerData.included).concat(scheduleAnswerData.included).concat(imageAnswerIncludedQuestions);
  userData.answers = {};
  userData.answeredQuestions = {};
  userData.eventAnswers = {};

  const existingUserData = !loadById && localStorage.getItem("imagine_user_data") ? JSON.parse(localStorage.getItem("imagine_user_data")) : {};

  function loadAnswersIntoUserData(userData, answerData, idGetter, userDataKey){
    if(!userData[userDataKey]){
      userData[userDataKey] = {};
    }
    for(let answer of answerData){
      const answerId = idGetter(answer);
      const existingAnswer =
        existingUserData[userDataKey] && existingUserData[userDataKey][answerId] ? existingUserData[userDataKey][answerId] : null;

      // determine when our answer was last changed locally.
      // this prevents the load from overwriting the value of an answer that was changed during loading.
      let existingChanged =
        existingAnswer && existingAnswer.attributes && existingAnswer.attributes.local_changed ? existingAnswer.attributes.local_changed: null;
      //existingChanged = Number.isInteger(existingChanged) ? existingChanged * 1000 : existingChanged; // normalize for timestamp in seconds since unix epoch
      //existingChanged = existingChanged ? new Date(existingChanged) : null;

      //const loadedAnswerIsOlder = existingChanged && new Date(answer.attributes.changed) < existingChanged;
      if(existingAnswer){
        delete existingUserData[userDataKey][answerId];
      }
      userData[userDataKey][answerId] = existingChanged ? mergeDeep(answer, existingAnswer) : answer;
      if (existingChanged) {
        delete userData[userDataKey][answerId].attributes.local_changed;
      }
    }
    Object.assign(userData[userDataKey], existingUserData[userDataKey]);
  }

  loadAnswersIntoUserData(userData, answerData, (answer) => answer.relationships.field_question_reference.data[0].id, 'answers');
  for(let q of includedQuestions){
    userData.answeredQuestions[q.id] = q;
  }
  loadAnswersIntoUserData(userData, eventAnswerData.data, (ea) => ea.id, 'eventAnswers');

  localStorage.setItem("imagine_user_data", JSON.stringify(userData));
  dispatch({
    type: 'updateUserData',
    payload: {
      userData: userData
    }
  });
}

/**
 *
 * This function uses the auth service to retrieve authenticated data. It checks if the user is still signed in after
 * the whole affair is over and updates the store if not.
 *
 * This should not be imported directly, as it expects to be in a logged in environment.
 * Let it be passed into your page component by wrapping it with the `UserContext`.
 *
 * @see signInToView
 * @see UserContextProvider
 *
 * @param {!Auth} auth The authentication service. This will be bound by UserContextProvider, don't pass it in page
 * components.
 * @param dispatch The dispatch function to the reducer. This will be bound by UserContextProvider, don't pass it in page
 * components.
 *
 * These are the parameters you will pass in your page components
 * @param {string} jsonapi_endpoint An endpoint to retrieve resources using the JSONAPI (https://jsonapi.org/). Includes
 * everything after the host except the leading slash (ex. `'articles?page[offset]=2'`)
 * @param {string} method An HTTP verb, mainly meant for `'GET'`,`'POST'`, `'PATCH'` and `'DELETE'`
 * @param {Object|null} body The body of the request, for `'POST'` and `'PATCH'`
 * @param headers
 *
 * @returns {Promise<object | Boolean>} A promise which will return a JSON object of content or false if it failed
 */
async function fetchAuthenticatedContent(auth, dispatch, jsonapi_endpoint, method='GET', body=null, headers=null){
  const content = await auth.drupalFetch(jsonapi_endpoint, method, body, headers);
  if(!content){
    dispatch({
      type: 'logoutUser',
      payload: {
        auth: auth
      }
    });
  }
  return content;
}

/**
 *
 * @param {object} refreshedToken
 * @param {!Auth} auth
 * @param dispatch
 */
const initializeLoginState = (auth, dispatch, refreshedToken) => {
  if(refreshedToken){
    dispatch({
      type: 'loginUser',
      payload: {
        auth: auth
      }
    });
  }
  else{
    localStorage.removeItem("imagine_user_data");
    dispatch({
      type: 'logoutUser',
      payload: {
        auth: auth
      }
    });
  }
}





/**
 * Wrap the context provider so that the consumer receives the functions from the auth service to login and fetch
 * authenticated content. These functions are bound to the store and will dispatch actions to change the
 * `isAuthenticated` state. Also initiates the process of determining if we are logged in on first page load.
 *
 * @param props
 * @returns {*} The
 * @constructor
 */
const UserContextProvider = props => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  let loginFunc = handleLogin.bind(null, auth, dispatch);
  let fetchCb = fetchAuthenticatedContent.bind(null, auth, dispatch);
  let logoutFunc = handleLogout.bind(null, auth, dispatch);

  let subrequestLoader = authSubrequestLoader.bind(null, auth, dispatch);
  let loadUserDataFunc = loadUserData.bind(null, subrequestLoader, dispatch);

  async function runInitialLoading(userData){
    dispatch({
      type: 'beginLoading'
    });
    for(let key in localStorage){
      if(key.startsWith('request:')){
        const requestQueue = JSON.parse(localStorage.getItem(key));
        const ret = await handleQueue(fetchCb, requestQueue, key, userData, dispatch);
      }
    }
    await loadUserData(subrequestLoader, dispatch);
    dispatch({
      type: 'completeLoading'
    });
  }

  // const existingUserData = JSON.parse(localStorage.getItem('imagine_user_data'));
  // dispatch({
  //   type: 'updateUserData',
  //   payload: {
  //     userData: existingUserData
  //   }
  // });
  let existingUserData = {};
  if(localStorage.getItem("imagine_user_data") !== null && Object.keys(state.userData).length === 0){
    existingUserData = JSON.parse(localStorage.getItem("imagine_user_data"));
    dispatch({
      type: 'updateUserData',
      payload: {
        userData: existingUserData
      }
    });
  }

  useEffect(() => {
    if(!state.hasOwnProperty('isAuthenticated')){
      let initLogin = initializeLoginState.bind(null, auth, dispatch);
      auth.refresh().then(initLogin);
    }
  }, [state.isAuthenticated]);


  useEffect(() => {
    if(state.isAuthenticated){
      runInitialLoading(existingUserData);
    }
  }, [state.isAuthenticated]);

  const user = {
    ...state,
    handleLogin: loginFunc,
    fetchAuthenticatedContent: fetchCb,
    dispatch: dispatch,
    handleLogout: logoutFunc,
    handleLoadUserData: loadUserDataFunc,
  };

  return (
    <UserContext.Provider
      value={ {
        ...user,
        getEntityUpdateHandler: getEntityUpdateHandler.bind(null, user)
      } }
    >
      {props.children}
    </UserContext.Provider>
  );
};

/**
 * A higher order component for wrapping components that need access to user specific content. Passes the prop `'user'`
 * to the component that it wraps.
 *
 * @param UserConsumerComponent
 * @returns {function(*): *}
 */
export function wrapUserConsumer(UserConsumerComponent){
   return props => (
     <UserContext.Consumer>
       {
         user => (
           <UserConsumerComponent {...props} user={user}>
             {props.children}
           </UserConsumerComponent>
         )
       }
     </UserContext.Consumer>
   );
}

/**
 * This is a wrapper for pages that users need to be signed in to see. Within these pages, consumers can invoke the
 * `UserContent.Consumer` (or use the wrapUserConsumer convenience function) in order to access the user specific information
 *
 * @see wrapUserConsumer
 * @param pageElement A page element
 * @returns {function(*): *} A wrapped page element which will display a login screen or shell unless you are signed
 * in.
 */
function signInToView(pageElement) {
  return (
    <UserContextProvider>
      <UserContext.Consumer>
        {
          user => (
            <div>
              {
                // Before we know if the user is logged in, display a shell
                !user.hasOwnProperty('isAuthenticated') && Object.keys(user.userData).length === 0 ? (
                      <ImagineSpinner />
                  ) :
                // We know whether or not the user is logged in now...
                !user.isAuthenticated && Object.keys(user.userData).length === 0  ? (
                  // ...so either show a login screen
                  <Layout>
                    <LoginForm user={user} />
                  </Layout>
                ) :
                  // ...or display the component
                  pageElement
              }
            </div>
          )
        }
      </UserContext.Consumer>
    </UserContextProvider>
  );
}
export default signInToView;
