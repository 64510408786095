import React from "react"
import { Link } from "gatsby"
import { wrapUserConsumer } from "../components/user-context";
import {Badge, Container, Row, Col, Button} from "reactstrap"


export function hasUnreadMessages(messages, currentUserId){

  if(typeof messages == "object"){
    messages = Object.values(messages);
  }

  return Object.values(messages).reduce( (acc, message) => {
    const messageCreator = message.hasOwnProperty('relationships') && message.relationships.hasOwnProperty('uid') ? message.relationships.uid.data.id : false;
    const messageReadStatus = message.hasOwnProperty('attributes') && message.attributes.hasOwnProperty('field_message_read') ? message.attributes.field_message_read : false;
    if(messageCreator && messageCreator != currentUserId && !messageReadStatus){
      return acc+1;
    }
    return acc;
  },0);
}

export function ConversationsLink({user}){


  const messages = user && user.hasOwnProperty('userData') && user.userData.hasOwnProperty('message') ? user.userData.message : {};
  const currentUser = user && user.hasOwnProperty('userData') && user.userData.hasOwnProperty('profile') ? Object.keys(user.userData.profile)[0] : null;

  const unreadMessageCount = hasUnreadMessages(messages, currentUser);
  const messageNotification = unreadMessageCount > 0 ? <Badge color="danger">{unreadMessageCount}</Badge> : null;

  return (
    <div>
      <Link className="btn-sm btn btn-secondary" to="/conversations">Conversations {messageNotification}</Link>
    </div>
  );
}

ConversationsLink = wrapUserConsumer(ConversationsLink);

function StartConversation({disabled, user, userId, userName}) {
  
  const classes = disabled ? "btn-sm btn btn-secondary disabled" : "btn-sm btn btn-secondary";

  return (
    <>
        <Col xs="auto" className="text-center my-auto px-1">
          <Link 
            to={"/newConversation"} 
            state={{userId: userId, name: userName}}
            className={classes}
          >
            Start Conversation
          </Link>
        </Col>
        <Col xs="auto" className="text-center my-auto px-1">
          <ConversationsLink />
        </Col>
    </>
  );
}

export default wrapUserConsumer(StartConversation);
