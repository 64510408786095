/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import { Link } from "@reach/router"
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Button
} from "reactstrap";

class AdminNavbar extends React.Component {
  render() {
    return (
      <>
        <Navbar
          className="navbar-horizontal navbar-main navbar-dark navbar-transparent bg-gradient-info"
          expand="lg"
          id="navbar-main"
        >
          <Container>
            <NavbarBrand to="/" tag={Link}>
              <img
                alt="..."
                src={require("../../assets/img/imagine-logo-wh.png")}
              />
            </NavbarBrand>
              <Button
                className="btn-neutral btn-icon"
                color="default"
                href="/login"
                target="_blank"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-key-25" />
                </span>
                <span className="nav-link-inner--text">Login</span>
              </Button>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
