
import { checkChapterComplete } from "../utils/questions";
import { generateScheduleTable } from "../utils/inserts";
import { siteMetadata } from "../../gatsby-config";
export function mergeDeep(target, source){
  const ret = {
    ...target,
    ...source
  };

  for(let targetKey of Object.keys(target)){
    if(source.hasOwnProperty(targetKey)
      && typeof source[targetKey] === 'object'
      && typeof target[targetKey] === 'object'
      && source[targetKey] !== null
      && target[targetKey] !== null
      && (!Array.isArray(target[targetKey])
      || !Array.isArray(source[targetKey]))
    ){
      ret[targetKey] = mergeDeep(target[targetKey], source[targetKey]);
    }
    else if(source.hasOwnProperty(targetKey)){
      ret[targetKey] = source[targetKey];
    }
    else{
      ret[targetKey] = target[targetKey];
    }
  }

  return ret;
}
export function doReplacements(chapterNodes, userData, body){
  const alreadyReplaced = {}, repeatedTokens = {};
  const replacementSets = [];
  const imgReplacementSets = [];
  const textReplacementSets = [];
  const scheduleReplacementSets = [];
  for(let chapterNode of chapterNodes){
    if(!checkChapterComplete(userData, chapterNode)){
      continue;
    }
    for(let sectionQuestions of chapterNode.relationships.field_sections.map(section => section.relationships.field_questions)){
      sectionQuestions = sectionQuestions.forEach((question) => {
        const answerToQuestion =
          userData.hasOwnProperty('answers') && userData.answers.hasOwnProperty(question.drupal_id) ?
            userData.answers[question.drupal_id] : false;
        if (!question) {
          return;
        }
        // only do replacements for completed questions
        if(answerToQuestion && answerToQuestion.attributes.field_is_complete){
          let answeredOptions = [];
          if(answerToQuestion.hasOwnProperty('relationships') &&
            answerToQuestion.relationships.hasOwnProperty('field_answer_option')){
            let answeredOptionsField = answerToQuestion.relationships.field_answer_option.data;
            if(Array.isArray(answeredOptionsField)){
              answeredOptions = answeredOptionsField;
            }
            else{
              answeredOptions = [answeredOptionsField];
            }
            replacementSets.push(extractReplacements(question, alreadyReplaced, repeatedTokens));
          }
          else if(answerToQuestion.hasOwnProperty('attributes') &&
            answerToQuestion.attributes.hasOwnProperty('field_answer')){
            const textAnswerOutput = answerToQuestion.attributes.field_answer.value;
            textReplacementSets.push(extractReplacements(question, alreadyReplaced, repeatedTokens, textAnswerOutput));
          } else if(answerToQuestion.hasOwnProperty('type') && 
              answerToQuestion.type === 'node--schedule_answer' && userData.eventAnswers){
            const scheduleTable = generateScheduleTable(userData.eventAnswers);
            scheduleReplacementSets.push(extractReplacements(question, alreadyReplaced, repeatedTokens, scheduleTable)); 
          }
          else if(answerToQuestion.type &&
            answerToQuestion.type === 'node--image_answer'){
            const imgUrl = `${siteMetadata.drupalUrl}${answerToQuestion.relationships.field_answer_image.data.attributes.uri.url}`;
            const img = `<img src="${imgUrl}" />`;
            imgReplacementSets.push(extractReplacements(question, alreadyReplaced, repeatedTokens, img));
          }
          for(let answeredOption of answeredOptions){
            // this should give us a single option node, elsewise the reference from the answer to the option is broke
            let answeredOptionNode = question.relationships.field_options.filter((op) => {
              return op.drupal_id === answeredOption.id
            })[0];
            // the answeredOptionNode can be undefined here as some options are either removed from questions or deleted 
            if (answeredOptionNode) {
              replacementSets.push(extractReplacements(answeredOptionNode, alreadyReplaced, repeatedTokens)) 
            };
          }
        }
      });
    }
  }
  body = body.replace(/\[([A-Za-z0-9\-]+)\]/g, '<span token="$1"></span>');
  return replaceDocument(replacementSets.concat(textReplacementSets).concat(scheduleReplacementSets).concat(imgReplacementSets), body, repeatedTokens);
}

export function replaceDocument(replacementSets, policyDoc, listTokens) {
  for (let replacements of replacementSets) {
    policyDoc = getReplacedDocument(policyDoc, replacements, listTokens);
  }

  const concatReplacements = [];
  // for each token which is supposed to represent a list of things
  for (let [listToken, listReplacements] of Object.entries(listTokens)) {
    let replacementObj = {};
    // concatenate all the replacements associated with that token into a comma-separated list
    listReplacements = listReplacements.map((replacement) => {
      return replacement.replace(/<\/?p>/g, "").replace(/\r?\n|\r/g, "");
    });
    replacementObj[listToken] = listReplacements.join(", ");

    // then push that concatenation as a single replacement
    concatReplacements.push(replacementObj);
  }
  // do the final set of replacements with all the list tokens and their associated lists
  policyDoc = getReplacedDocument(policyDoc, concatReplacements);
  // return string with span <tags> removed, empty <p> tags removed and nbsp removed
  let retStr = policyDoc.replace(/<\/*(span)[^>]*>/g, decideIfBreak).replace(/\u00AD/g, "").replace(/&nbsp;/g, "").replace(/<p>\s*\r*\s*\n*\s*<\/p>/g, "");
  return retStr;
}

function decideIfBreak(str) {
  if (str.includes("page-break") || str.includes("table-of-contents")) {
    return str + '</span>';
  }
  return "";
}

export function getDocumentReplacer(replacementSets, listTokens){
  function replaceDocumentAndInsertIntoPost(entity, previousPostBody){
    let policyDoc = entity.attributes.field_document.value;
    policyDoc = replaceDocument(replacementSets, policyDoc, listTokens);
    const setDocumentValue = {
      data: {
        attributes: {
          field_document: {
            value: policyDoc
          }
        }
      }
    };

    return mergeDeep(previousPostBody, setDocumentValue);
  }
  return replaceDocumentAndInsertIntoPost;
}

/**
 * Returns a policy document where tokens have been replaced with text.  If `listReplacements` is provided, it defers
 * applying replacements where `listReplacements[token]` is set and pushes them onto the attached array at that index,
 * as they will be concatenated into a comma separated list after the first iteration through all the replacements in a
 * section.
 * @param policyDoc
 * @param replacements
 * @param listReplacements
 * @returns {string}
 */
function getReplacedDocument(policyDoc, replacements, listReplacements=false){
  const policyEl = document.createElement('div');
  policyEl.innerHTML = policyDoc;
  if(!Array.isArray(replacements)){
    replacements = [replacements];
  }
  for (let replacement of replacements) {
    // should only be one entry per replacement but this is a loop regardless
    for (let [token, text] of Object.entries(replacement)) {
      if(listReplacements && listReplacements.hasOwnProperty(token)){
        // this replacement applies to a token which represents a list of things, which this replacement text is one of.
        // so don't do this replacement yet, wait till we've collected them all together.
        listReplacements[token].push(text);
        continue;
      }
      // convert the bracketed tokens in the replacement text into span tags that another policy output can fill
      text = text.replace(/\[([A-Za-z0-9\-]+)\]/g, '<span token="$1"></span>');

      // find the span tags in the current policy that match our token
      let tokenEls = policyEl.querySelectorAll(`[token="${token}"]`);

      // fill each span element matching our token with our replacement text
      for (let tokenEl of tokenEls) {
        tokenEl.innerHTML = text;
      }
    }
  }
  return policyEl.innerHTML;
}

export function extractReplacements(objWithReplacement, prevReplacements, listTokens,  outputText=null){
  if(!objWithReplacement.hasOwnProperty('relationships') ||
    !objWithReplacement.relationships.hasOwnProperty('field_policy_output')){
    return {};
  }
  const replacementArr = objWithReplacement.relationships.field_policy_output.map((replacement) => {
    const replacementObj = {};
    let replacementText = outputText ? outputText : replacement.field_replacement_text.value;

    // if there is a p tag wrapping the entire replacement text, we want to get rid of it. this p tag is just an
    // artifact of the text editor and gives us an undesired line break in the final text.
    replacementText = replacementText.replace(/\r?\n|\r/g, "").trim(); //first remove line breaks and trim whitespace
    replacementText = replacementText.replace(/^<p>(.*)<\/p>$/g, "$1"); // then remove outer p tag

    replacementObj[replacement.field_token] = replacementText;
    if(prevReplacements.hasOwnProperty(replacement.field_token)){
      listTokens[replacement.field_token] = [];
    }
    prevReplacements[replacement.field_token] = true;
    return replacementObj;
  });

  return replacementArr;
}
