import React from 'react';

const withMessage = WrappedComponent => {
  return class WithMessage extends React.Component {
    state = {
      message: '',
    }
    setMessage = newMessage => {
      this.setState({message: newMessage})
    }
    render() {
      return (
        <WrappedComponent
          {...this.props}
          setMessage={this.setMessage}
          message={this.state.message}
        />
      )
    }
  }
}

export default withMessage;
