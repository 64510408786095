/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// core components
import AuthNavbar from "../../components/navbar/AuthNavbar";
import AuthFooter from "../../components/footer/AuthFooter"
import Header from "../header/header.js"
//import Chat from "../chat.js"
import PropTypes from "prop-types"

//import "./layout.css"

const AuthLayout = (props) => {
  if (typeof document !== `undefined`) document.body.classList.add("bg-default");
  return (
    <>
      <div className="main-content">
        <AuthNavbar />
        <Header {...props} />
        {props.children}
      </div>
      <AuthFooter {...props} />
    </>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthLayout
