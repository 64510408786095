/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */


import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import AdminLayout from "./AdminLayout"
import AuthLayout from "./AuthLayout"
import { wrapUserConsumer } from "../../components/user-context.js"
import { Redirect } from "@reach/router"
import { NotificationWrapper } from "../notifications.js"

//import "./layout.css"

const Layout = (props) => {
  useEffect(() => {
    return function cleanup() {
      let elem = document.querySelector('.d-flex.justify-content-center');
      if (elem) {
        let classList = elem.classList;
        classList.remove('d-flex');
        classList.remove('justify-content-center');
      }
    }
  });
  let layout = (
      <AuthLayout {...props} />
    )
  // Need to see user context here to check whether we're returning an AdminLayout or an AuthLayout
  if (props.location && props.location.pathname !== '/login/') {
    if (typeof props.user !== `undefined` && (props.user.isAuthenticated || Object.keys(props.user.userData).length !== 0)) {
      layout = (
        <AdminLayout {...props} />
      );
    } else if (props.location.pathname !== '/') {
      props.user = {}
      layout = (
        <AdminLayout {...props} />    
      )
    }
  } else if (typeof props.user !== `undefined` && props.user.isAuthenticated) {
    return ( <Redirect noThrow to="/dashboard" /> );
  }
  return (
    <NotificationWrapper location={props.location}>
      { layout }
    </NotificationWrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default wrapUserConsumer(Layout)
