/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

import AdminNavbar from "../navbar/AdminNavbar"
import AdminFooter from "../footer/AdminFooter"
import Header from "../header/header.js"
import Chat from "../chat.js"
import PropTypes from "prop-types"
import Disclaimer from "../disclaimer";

const AdminLayout = (props) => {
  if (typeof document !== `undefined`) document.body.classList.remove("bg-default");
  return (
    <>
      <Disclaimer user={props.user}/>
      <div className="main-content">
        <AdminNavbar {...props} />
        <Header {...props} />
        {props.children}
      </div>
      <AdminFooter />
    </>
  );
}


AdminLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AdminLayout;
